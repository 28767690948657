import React from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Mail';
import { Button } from '@mui/material';

function EmailView({
  email, ...otherProps
}) {
  if (!email) return null;
  const props = { component: 'a', href: `mailto:${email}` };
  return (
    <Button
      style={{ margin: 2 }}
      color="primary"
      size="large"
      {...props}
      {...otherProps}
    >
      <EmailIcon />

      <span style={{ textTransform: 'none' }}>{email}</span>
    </Button>
  );
}

EmailView.propTypes = {
  email: PropTypes.string,
};

EmailView.defaultProps = {
  email: undefined,
};

export default EmailView;
