import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Popper, TextField } from '@mui/material';
import LinkSuggestions from './link-suggestions';

const style = {
  div: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },
  textField: {
    color: '#00BCD4',
  },
};

function LinkField({
  onChange, maxLength, value, ...props
}) {
  const [anchorEl, setAnchorEl] = useState();
  const [openSuggest, setOpenSuggest] = useState(false);

  const handleChange = useCallback((event) => {
    setAnchorEl(event.target);
    onChange(event.target.value);
    setOpenSuggest(true);
  }, [onChange]);

  const onSuggestion = useCallback((event) => {
    onChange(event.target.value);
    setOpenSuggest(false);
  }, [onChange]);
  return (
    <div style={style.div}>
      <TextField
        variant="standard"
        style={style.textField}
        placeholder="www.something.anything"
        onChange={handleChange}
        value={value}
        {...props}
        onBlur={() => { setOpenSuggest(false); }}
        inputProps={{ maxLength }}
        fullWidth
      />
      {anchorEl && (
        <Popper
          open={openSuggest}
          placement="bottom-start"
          disablePortal={false}
          anchorEl={anchorEl}
          style={{ zIndex: 999999999 }}
        >
          <LinkSuggestions value={value} onChange={onSuggestion} />
        </Popper>
      )}
    </div>
  );
}

LinkField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  maxLength: PropTypes.number,
};

LinkField.defaultProps = {
  maxLength: undefined,
  value: '',
};

export default LinkField;
