import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

export const toUnixTime = (timeValue) => {
  if (timeValue instanceof Date) {
    return Math.round(timeValue / 1000);
  }
  throw new Error('Time value must be a Date Object instance');
};

export const toISOdate = unixTime => moment(new Date(unixTime * 1000)).format('YYYY-MM-DDTHH:mm');

const styles = {
  div: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },

  textField: {
    color: '#00BCD4',
  },
};
export function DateTimePicker({
  value, min, onChange, classes, label, InputLabelProps, ...rest
}) {
  let val = value && !min && toISOdate(value);
  if (min) {
    val = value && value >= min ? toISOdate(value) : toISOdate(min);
  }
  return (
    <div className={classes.div}>
      <TextField
        variant="standard"
        className={classes.textField}
        label={label}
        InputLabelProps={InputLabelProps}
        inputProps={rest}
        value={val}
        onChange={(e) => {
          if (e.target.value) {
            const unixTimeStamp = toUnixTime(new Date(e.target.value));
            if (!min) onChange(unixTimeStamp);
            else if (unixTimeStamp >= (min - 59)) onChange(unixTimeStamp);
          }
        }}
        type="datetime-local"
        fullWidth
      />
    </div>
  );
}

DateTimePicker.propTypes = {
  label: PropTypes.string,
  InputLabelProps: PropTypes.shape({}),
  value: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

DateTimePicker.defaultProps = {
  label: 'When',
  value: 0,
  min: undefined,
  InputLabelProps: {
    shrink: true,
  },
};

export default withStyles(styles)(DateTimePicker);
