import React from 'react';

function UserForm() {
  return (
    <h1>
      Nice Try! but No!
    </h1>
  );
}

export default UserForm;
