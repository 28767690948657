import { pathToRegexp } from 'path-to-regexp';
import { IBaseApplet } from 'packages/model/interface';
import useNavigation from 'packages/use-navigation';
import { useEffect, useState } from 'react';

export function useRouteParams() {
  const { location: { pathname = '' } } = useNavigation();
  const appletPattern = '/@:username([0-9a-zA-Z-_.]+)/:appId([0-9a-zA-Z-_.]+)?/:appletId([0-9a-zA-Z-_.]+)?';
  const optionsPattern = ':action([0-9a-zA-Z-_.]+)/:property([0-9a-zA-Z-_.]+)?';
  const appletRe = pathToRegexp(appletPattern);
  const optRe = pathToRegexp(optionsPattern);
  const [path] = pathname.split('?');
  const [appletPath, optionsPathAndSearch] = path.split('~');
  const [optionsPath] = optionsPathAndSearch ? optionsPathAndSearch.split('?') : ['', ''];

  const [, username, appId, appletId] = appletRe.exec(appletPath) || [];
  const [, action, property] = optRe.exec(optionsPath) || [];
  const ownerName = appId ? username : undefined;
  return ownerName ? {
    appId,
    appletId,
    ownerName,
    action,
    property,
  } : {
    username, action, property,
  };
}

export function useAppConfig() {
  return {
    appName: process.env.NEXT_PUBLIC_APP_NAME || 'Yote',
    appHomeOwner: process.env.NEXT_PUBLIC_HOME_OWNERNAME || 'yote',
    appHomeAppId: process.env.NEXT_PUBLIC_HOME_APP || 'home',
    appDescription: process.env.NEXT_PUBLIC_APP_DESC || '',
  };
}

export function useAppletPath(applet:IBaseApplet) {
  if (applet && applet._meta) {
    if (applet.type === 'User') {
      return applet._meta.username ? `@${applet._meta.username}` : '';
    }
    if (applet._meta.appId === applet._meta.appletId) {
      return `@${applet._meta.ownerName}/${applet._meta.appId}`;
    }
    return `@${applet._meta.ownerName}/${applet._meta.appId}/${applet._meta.appletId}`;
  }
  return '';
}
export function useOrigin() {
  let origin = process.env.NEXT_PUBLIC_APP_URL;
  if (!origin && typeof window !== 'undefined') {
    origin = window.location.origin;
  }
  return origin;
}

export function useLocalStorage() {
  const [storage, setStorage] = useState<Storage | undefined>(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setStorage(window.localStorage);
    }
  }, []);

  return {
    setItem: (key: string, value: string) => {
      if (storage) {
        storage.setItem(key, value);
      }
    },
    getItem: (key: string) => {
      if (storage) {
        return storage.getItem(key);
      }
      return undefined;
    },
    removeItem: (key: string) => {
      if (storage) {
        storage.removeItem(key);
      }
    },
  };
}

const helperHooks = { useRouteParams, useAppConfig };
export default helperHooks;
