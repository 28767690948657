export default {
  name: 'Items',
  edit: {
    form: 'list-edit',
    label: 'Edit List Items',
    handler: (value, id) => {
      const val = { ...value };
      if (id) delete val[id];
      return val;
    },
  },
};
