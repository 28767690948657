import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import setPropTypes from 'recompose/setPropTypes';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';

export default compose(
  setPropTypes({
    prompts: PropTypes.arrayOf(PropTypes.string).isRequired,
    buttonProps: PropTypes.shape({
      href: PropTypes.string.isRequired,
    }).isRequired,
  }),
  withState('promptReplacements', 'setPromptReplacements', ({ prompts }) => {
    const replacements = {};
    prompts.forEach((prompt) => {
      replacements[prompt] = '';
    });
    return replacements;
  }),
  withHandlers({
    onPromptChange: ({
      setPromptReplacements,
      promptReplacements,
    }) => (prompt, replacement) => {
      const newReplacements = { ...promptReplacements, [prompt]: replacement };
      setPromptReplacements(newReplacements);
    },
  }),
);
