const dayExclusions = (openingHours) => {
  const exclusions = [];
  openingHours.forEach(({ days }) => {
    days.forEach((day) => {
      exclusions.push(day);
    });
  });
  return exclusions;
};

export default dayExclusions;
