import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import defaultStyles from '../../../styles/views/mini-card';
import MiniCardBase from '../../../../view-widgets/mini-card/base';

const getHostname = (url) => {
  try {
    return new URL(url).hostname;
  } catch (error) {
    return url;
  }
};

function MiniCard({
  applet: {
    name, url, image, description, logo,
  },
  classes,
  ...otherProps
}) {
  return (
    <MiniCardBase
      imageURL={image && image.contentURL}
      logoURL={logo && logo.contentURL}
      title={name}
      subheader={getHostname(url)}
      description={description}
      classes={classes}
      {...otherProps}
    />
  );
}

MiniCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(defaultStyles)(MiniCard);
