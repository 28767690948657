const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const nextOpeningHoursToday = (today, nowLocalTime, openingHours) => {
  const nowHours = nowLocalTime.getUTCHours();
  const nowMinutes = `${nowLocalTime.getUTCMinutes()}`;
  const nowMillitaryTime = Number(`${nowHours}${nowMinutes.length === 1 ? `0${nowMinutes}` : nowMinutes}`);
  const todayOpeningHours = openingHours.filter(({ days }) => days.includes(today))[0].openHours;
  const todayNextOpeningHours = todayOpeningHours.filter(({ from }) => Number(from.replace(':', '')) > nowMillitaryTime)[0];
  if (todayNextOpeningHours) {
    const nextOpenDay = today;
    const nextOpeningHours = todayNextOpeningHours.from;
    return [nextOpenDay, nextOpeningHours];
  }
  return [];
};

const nextOpeningDayAndHours = (todayIndex, allOpenDays, openingHours) => {
  const daysAhead = weekDays.filter(
    (day, index) => allOpenDays.includes(day) && index > todayIndex,
  );

  const daysBefore = weekDays.filter(
    (day, index) => allOpenDays.includes(day) && index < todayIndex,
  );

  const nextOpenDay = daysAhead.length ? daysAhead[0] : daysBefore[0];
  const [nextOpenHours] = openingHours.filter(({ days }) => days.includes(nextOpenDay));
  const nextOpeningHours = nextOpenHours.openHours[0].from;
  return [nextOpenDay, nextOpeningHours];
};

const placeNextOpenHours = (openingHours) => {
  const allOpenDays = openingHours.reduce((acc, { days }) => [...acc, ...days], []);
  const { timezoneOffset } = openingHours[0];
  const nowLocal = new Date(Date.now() - (timezoneOffset * 60 * 1000));
  const todayIndex = nowLocal.getUTCDay();
  const today = weekDays[todayIndex];

  let nextOpenDay;
  let nextOpeningHours;
  if (allOpenDays.includes(today)) {
    [nextOpenDay, nextOpeningHours] = nextOpeningHoursToday(today, nowLocal, openingHours);
  }

  if (!nextOpeningHours) {
    [nextOpenDay, nextOpeningHours] = nextOpeningDayAndHours(todayIndex, allOpenDays, openingHours);
  }
  return [nextOpenDay, nextOpeningHours];
};

export default placeNextOpenHours;
