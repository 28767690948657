import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import PlacesAutoComplete from '../../../../forms/places-autocomplete';
import mapsStaticSource from '../../utils/maps-static-image';

function Form({ value, onChange, isValidCallback }) {
  if (isValidCallback) {
    if (value && value.address && value.address.trim()) {
      isValidCallback(true);
    } else {
      isValidCallback(false);
    }
  }
  const onPlaceUpdated = useCallback((val) => {
    onChange({
      ...value,
      name: val.name,
      address: val.formatted_address,
      phoneNumber: val.international_phone_number || '',
      geometry: val.geometry,
      website: val.website || '',
      image: {
        contentURL: mapsStaticSource(val.formatted_address
          .replace(/,\s/g, ',+').replace(/\s/g, '+')),
      },
      actions: [
        {
          name: 'Take Me There',
          target:
            `https://maps.google.com/?saddr=Current+Location&daddr=${val.geometry.location.lat},${val.geometry.location.lng}`,
        },
      ],
    });
  }, [onChange, value]);
  return <PlacesAutoComplete value={value.address} onChange={onPlaceUpdated} />;
}

Form.propTypes = {
  actionArea: PropTypes.shape({}).isRequired,
  value: PropTypes.shape({
    address: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isValidCallback: PropTypes.func,
};

Form.defaultProps = {
  isValidCallback: undefined,
  value: {
    address: '',
  },
};
export default Form;
