type ImageProps = {
    src: string;
    [key: string]: unknown;
}
function ImageWidget(props:ImageProps) {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
    <img
      {...props}
    />
  );
}
export default ImageWidget;
