export default {
  name: 'Link',
  edit: {
    form: 'link-input',
    label: 'Edit Link',
    handler: value => value,
  },
  linkedProperties: [
    {
      name: 'actions',
      transform: (currentActions, newUrl) => {
        try {
          const newActions = [...currentActions];
          newActions[0].target = newUrl;
          return newActions;
        } catch {
          return [];
        }
      },
    },
  ],
};
