import * as properties from './properties';
import * as thingProperties from '../Thing/properties';
import * as views from './views';
import Avatar from './avatar';
import BasicForm from './forms/basic';

const {
  Card,
  MiniCard,
  ListCardItem,
  ListItem,
  Accordion,
  ImageViewer,
} = views;

const EventExtension = {
  views: {
    card: () => Card,
    miniCard: () => MiniCard,
    listCardItem: () => ListCardItem,
    listItem: () => ListItem,
    expansionPanel: () => Accordion,
    imageViewer: () => ImageViewer,
  },
  forms: {
    basic: () => BasicForm,
  },
  avatar: () => Avatar,
  properties: { ...thingProperties, ...properties },
  meta: { name: 'Event' },
};
export default EventExtension;
