import useNavigation from 'packages/use-navigation';

function useActionButtonConfig(target: string) {
  const { Link } = useNavigation();
  if (target.startsWith(window.location.origin)) {
    return {
      component: Link,
      to: target,
    };
  }
  if (target.startsWith('tel:')) {
    const href = target.replace(/#/g, '%23');
    return {
      component: 'a',
      href,
    };
  }
  return {
    component: 'a',
    href: target,
    target: '_blank',
    rel: 'noopener noreferrer',
  };
}

export default useActionButtonConfig;
