export default (center) => {
  switch (process.env.REACT_APP_DEPLOY_TARGET) {
    case 'production': {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=13&scale=false&size=600x300&maptype=roadmap&key=AIzaSyCo8UpMUnuQi3OycPcmmyO4ljRC3SsCUMY&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${center}`;
    }
    case 'staging': {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=13&scale=false&size=600x300&maptype=roadmap&key=AIzaSyByhPdSRkK9UeWSawIN-bddPVL-ZLvMx8M&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${center}`;
    }
    default: {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=13&scale=false&size=600x300&maptype=roadmap&key=AIzaSyAnqT29GiydiA2wWW7JJf0SoWX1TEH5n5I&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${center}`;
    }
  }
};
