const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
      padding: '16px',
    },
    '&>:last-child': {
      marginTop: 'auto',
    },
  },
  container: {
  },
  text: {
    padding: 16,
  },
  timeNameIconDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
  },
  shortTime: {
    textAlign: 'center',
    marginRight: '0.8em',
    fontSize: '1em',
  },
  price: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  startDate: {
    // fontSize: '1em',
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  repeatView: {
    // fontSize: '1em',
    // margin: '0.6em 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.9em',
  },

  bannerImage: {
    width: '100%',
    background: 'blue',
    height: 'auto',
  },
  bannerWrapper: {
    maxHeight: '25vh',
    overflow: 'hidden',
    transition: 'max-height 0.4s ease-out',
    '&:hover': {
      maxHeight: '100vh',
      transition: 'max-height 0.4s ease-in',
    },
  },
  description: {
    // marginTop: '0.6em',
  },
  name: {
    display: 'flex',
    alignItems: 'baseline',
  },
  openingHoursContainer: {
    marginTop: '15px',
    marginBottom: '10px',
    display: 'grid',
    gridRowGap: '20px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  link: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 16,
    color: '#808080',
    textDecoration: 'none',
  },
  linkWithMargin: {
    marginLeft: 8,
    padding: 5,
    fontSize: 16,
    color: '#808080',
    textDecoration: 'none',
    display: 'block',
  },
  linkInner: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  imageEditLink: {
    float: 'right',
    color: '#fff',
    backgroundColor: '#000000aa',
    zIndex: 10,
    position: 'relative',
    top: -42,
    left: -2,
    fontSize: 25,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '3px 5px',
  },

  imageAddIcon: {
    fontSize: 80,
  },

  appletsDiv: {
    // padding: 8,
  },

  divider: {
    margin: '7px 0',
  },
};

styles.imageAddLink = {
  ...styles.imageEditLink,
  top: undefined,
  float: undefined,
  marginBottom: 10,
  fontSize: 30,
  flexDirection: 'column',
  justifyContent: 'center',
};

styles.brand = styles.price;

export default styles;
