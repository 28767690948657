import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/list-card-item';
import PhoneNumberView from '../phone-number-view';
import EmailView from '../email-view';
import ListCardBase from '../../../../view-widgets/list-card-item/base';

function ListCard({
  applet: {
    name, image, telephone, email,
  },
  url,
  classes,
  ...otherProps
}) {
  const title = (
    <Typography>
      {name}
    </Typography>
  );
  const linkedTitle = url && (
    <Link
      to={url}
      href={url}
      style={{ textDecoration: 'none' }}
    >
      {title}
    </Link>
  );
  const detail = (
    <div>
      <PhoneNumberView telephone={telephone} size="medium" />
      <EmailView email={email} size="medium" />
    </div>
  );
  return (
    <ListCardBase
      title={linkedTitle || title}
      detail={detail}
      imageURL={image && image.contentURL}
      classes={classes}
      {...otherProps}
    />
  );
}

ListCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  url: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
};

ListCard.defaultProps = {
  url: undefined,
};
export default withStyles(styles)(ListCard);
