import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/list-card-item';
import ListCardBase from '../../../../view-widgets/list-card-item/base';

function ListCard({
  applet: {
    name, brand, image,
  },
  classes,
  ...otherProps
}) {
  return (
    <ListCardBase
      title={name}
      detail={brand}
      imageURL={image && image.contentURL}
      classes={classes}
      {...otherProps}
    />
  );
}

ListCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ListCard);
