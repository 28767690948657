import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },

  textField: {
    color: '#00BCD4',
  },
};

function NameInput({
  value, onChange, maxLength, classes, ...otherProps
}) {
  return (
    <div className={classes.container}>
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        inputProps={{ maxLength }}
        className={classes.textField}
        {...otherProps}
        fullWidth
      />
    </div>
  );
}

NameInput.propTypes = {
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
};
NameInput.defaultProps = {
  value: '',
  maxLength: 60,
  required: true,
  label: 'Name',
};

export default withStyles(styles)(NameInput);
