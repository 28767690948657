import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import setPropTypes from 'recompose/setPropTypes';
import defaultProps from 'recompose/defaultProps';
import scriptLoader from 'react-async-script-loader';
import { geocodeByAddress } from 'react-places-autocomplete';

export const getCorrectMapsScript = () => {
  switch (process.env.REACT_APP_DEPLOY_TARGET) {
    case 'production': {
      return 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDOwDG4ngCosc1YmO_Msq05chkRj1OAV8s&libraries=geometry,places';
    }
    case 'staging': {
      return 'https://maps.googleapis.com/maps/api/js?key=AIzaSyByhPdSRkK9UeWSawIN-bddPVL-ZLvMx8M&libraries=geometry,places';
    }
    default: {
      return 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAnqT29GiydiA2wWW7JJf0SoWX1TEH5n5I&libraries=geometry,places';
    }
  }
};

const onSelect = ({ onChange, setValue }) => (value) => {
  setValue(value);
  geocodeByAddress(value)
    .then((results) => {
      const placesService = new window.google.maps.places.PlacesService(document.createElement('span'));
      placesService.getDetails({
        placeId: results[0].place_id,
      }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const placeFormatted = JSON.parse(JSON.stringify(place).replace(/<[^>]*>/g, ''));
          onChange(placeFormatted);
        }
      });
    });
};

export default compose(
  scriptLoader(getCorrectMapsScript()),
  setPropTypes({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  defaultProps({
    value: '',
  }),
  withState('value', 'setValue', value => value.value),
  withHandlers({
    onSelect,
  }),
);
