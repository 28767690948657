import React from 'react';
import PropTypes from 'prop-types';
import CallIcon from '@mui/icons-material/Call';
import { Button } from '@mui/material';

function PhoneView({
  telephone, edit, editURL, ...otherProps
}) {
  if (!telephone) return null;
  const props = {
    component: 'a',
    href: `tel:${telephone}`,
  };
  return (
    <Button
      style={{ margin: 2 }}
      color="primary"
      size="large"
      {...props}
      {...otherProps}
    >
      <CallIcon />

      { telephone }
    </Button>
  );
}

PhoneView.propTypes = {
  telephone: PropTypes.string,
  edit: PropTypes.bool,
  editURL: PropTypes.string,
};

PhoneView.defaultProps = {
  telephone: undefined,
  edit: false,
  editURL: '',
};

export default PhoneView;
