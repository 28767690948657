import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from 'packages/extensions/styles/views/card';
import CardBase from 'packages/view-widgets/card/base';

function CardView({ applet, classes, ...otherProps }) {
  const {
    name, description, image, actions, _meta: { username }, customProperties,
  } = applet;
  const subheader = (
    <Typography variant="caption" noWrap>
      {`@${username}`}
    </Typography>
  );
  return (
    <CardBase
      image={image}
      title={name}
      description={description}
      subheader={subheader}
      actions={actions}
      customProperties={customProperties}
      classes={classes}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
    _meta: PropTypes.shape({
      username: PropTypes.string,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CardView);
