import getAppletPath from '../../../utils/create-url';

export default {
  name: 'Area Served',
  edit: {
    form: 'applet-input',
    label: 'Edit Area Served Applet',
    handler: value => value,
    getAltEditLink: (parent) => {
      const searchParams = new URLSearchParams();
      searchParams.set('property', 'areaServed');
      searchParams.set('allowedTypes', ['Place']);
      const pathname = `/${getAppletPath(parent)}/~create/Thing`;
      return {
        label: 'Create New',
        pathname,
        search: `?${searchParams.toString()}`,
        state: {
          presets: {
            name: 'Areas Served',
            description: `Areas served by ${parent.name}`,
          },
        },
      };
    },
  },
};
