import React from 'react';
import { useSelector } from 'react-redux';
import ItemFetcher from '../ItemFetcher';

export function useLocalApplet(id) {
  return useSelector(state => state.applets[id]);
}
const renderItemFetcherIfNoLocalApplet = C => (function (p) {
  const { id } = p;
  const applet = useLocalApplet(id);
  return applet ? <C {...p} applet={applet} /> : <ItemFetcher id={id} />;
});

export default renderItemFetcherIfNoLocalApplet;
