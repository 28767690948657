import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import OpeningHoursView from '../opening-hours-view';
import styles from '../../../styles/views/expansion-panel';
import withOpenStatus from '../../utils/with-place-open-status';
import BaseExpPanel from '../../../../view-widgets/expansion-panel/base';
import DefaultAvatar from '../../avatar';

function PanelListItem({
  applet: {
    name,
    logo,
    image,
    openingHours,
    actions,
  },
  classes,
  ...otherProps
}) {
  const daysInOpeningHours = openingHours.reduce((acc, { days }) => [...acc, ...days], []);
  const daysNotInOpeningHours = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].filter(day => !daysInOpeningHours.includes(day));
  const OpenStatus = withOpenStatus(({ openStatus }) => <span>{openStatus}</span>);
  const NextOpening = withOpenStatus(({ nextOpening }) => <span>{nextOpening}</span>);

  const title = <OpenStatus openingHours={openingHours} />;
  const caption = <NextOpening openingHours={openingHours} />;

  const detail = (
    <div className={classes.openingHoursContainer}>
      <Typography variant="h6">
        {name}
      </Typography>
      {
        openingHours.map((hours, index) => {
          const reactKey = `open-hours-${index}`;
          return <OpeningHoursView key={reactKey} openingHours={hours} />;
        })
      }
      {
        daysNotInOpeningHours.length !== 0
          && <OpeningHoursView openingHours={{ days: daysNotInOpeningHours }} />
      }
    </div>
  );

  const avatar = (logo && logo.contentURL) || (image && image.contentURL) || <DefaultAvatar />;

  return (
    <BaseExpPanel
      avatar={avatar}
      primary={title}
      caption={caption}
      secondary={detail}
      actions={actions}
      classes={classes}
      {...otherProps}
    />
  );
}

PanelListItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    openingHours: PropTypes.arrayOf(PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.string),
      openingHours: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      })),
      timezoneOffset: PropTypes.number,
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(PanelListItem);
