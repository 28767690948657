import { MenuItem, MenuList, Paper } from '@mui/material';
import React, { ChangeEvent } from 'react';
import getSuggestions, { Suggestion } from './get-suggestions';

interface PropTypes {
  value: string;
  onChange: (event: ChangeEvent) => void;
}
function renderSuggestions(
  suggestions: Suggestion[],
  onChange: (event: any) => void,
): React.ReactNode {
  return suggestions.map(({ label, value: val }) => {
    const onSelect = () => {
      onChange({ target: { value: val } });
    };
    return (
      <MenuItem key={label} onMouseDown={onSelect} value={val}>
        {`${label}`}
      </MenuItem>
    );
  });
}

function LinkSuggestions({ value, onChange }: PropTypes) {
  const suggestions = getSuggestions(value);
  return (
    <Paper>
      <MenuList>{renderSuggestions(suggestions, onChange)}</MenuList>
    </Paper>
  );
}

export default LinkSuggestions;
