const nextDailyEvent = (applet) => {
  const {
    startDate,
    recurrence: {
      recurrenceRule: { interval },
    },
  } = applet;
  const intervalDaysInSeconds = interval * 24 * 60 * 60;
  if (startDate >= Math.round(Date.now() / 1000)) return startDate;
  return nextDailyEvent({ ...applet, startDate: startDate + intervalDaysInSeconds });
};

export default nextDailyEvent;
