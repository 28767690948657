import React from 'react';
import { Link } from 'packages/use-navigation/navigation-context';
import PropTypes from 'prop-types';
import Edit from 'react-icons/lib/md/create';
import Add from 'react-icons/lib/md/add';
import { IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  rootTypo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    whiteSpace: 'pre-wrap',
  },

  linkInner: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  link: {
    marginLeft: 8,
    marginRight: 8,
    color: '#808080',
    textDecoration: 'none',
  },
};

function DescriptionView({
  description, edit, editURL, classes,
}) {
  return (description || edit)
  && (
  <Typography className={classes.rootTypo}>
    {description && description}
    {
        edit
        && (
        <Link to={editURL} href={editURL} className={classes.link}>
          {
            description
              ? (
                <IconButton size="large">
                  <Edit />
                </IconButton>
              )
              : (
                <span className={classes.linkInner}>
                  <Add />

                  Add Desciption
                </span>
              )
            }
        </Link>
        )
    }
  </Typography>
  );
}

DescriptionView.propTypes = {
  description: PropTypes.string,
  editURL: PropTypes.string,
  edit: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
};

DescriptionView.defaultProps = {
  description: undefined,
  editURL: '',
  edit: false,
};

export default withStyles(styles)(DescriptionView);
