import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import {
  ListItemAvatar, ListItemText, ListItem, Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const listItemTextStyles = {
  secondary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  primary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export function ListItemView({
  primary,
  secondary,
  avatar,
  url,
  viewProps,
  classes,
  onClick,
}) {
  let ButtonComp = 'div';
  let target = '';
  let rel = '';
  if (url && url.startsWith('/')) {
    ButtonComp = Link;
  } else if (url) {
    ButtonComp = 'a';
    if (!url.startsWith('tel:')) {
      target = '_blank';
      rel = 'noopener';
    }
  }

  const ListItemTextWithStyles = withStyles(listItemTextStyles)(ListItemText);

  return (
    <Paper
      className={classes.root}
      elevation={viewProps.elevation}
      square={viewProps.square}
    >
      <ListItem
        button={!!url || !!onClick}
        component={ButtonComp}
        href={url}
        rel={rel}
        to={url}
        onClick={onClick}
        target={target}
      >
        <ListItemAvatar>{avatar}</ListItemAvatar>
        <ListItemTextWithStyles
          primary={primary}
          secondary={secondary}
          primaryTypographyProps={{ noWrap: true, component: 'p' }}
        />
      </ListItem>
    </Paper>
  );
}

ListItemView.propTypes = {
  primary: PropTypes.node,
  secondary: PropTypes.node,
  avatar: PropTypes.shape({
    contentURL: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  url: PropTypes.string,
  onClick: PropTypes.func,
  viewProps: PropTypes.shape({
    square: PropTypes.bool,
    elevation: PropTypes.number,
  }),
  classes: PropTypes.shape({}),
};

ListItemView.defaultProps = {
  primary: undefined,
  secondary: undefined,
  avatar: undefined,
  url: undefined,
  onClick: undefined,
  viewProps: {},
  classes: {},
};

export default ListItemView;
