const datePartsGrabber = (timeStamp) => {
  const dateValue = new Date(timeStamp * 1000);
  return [
    dateValue.getUTCDate(),
    dateValue.getUTCMonth(),
    dateValue.getUTCFullYear(),
  ];
};
const numberOfDaysInMonth = (year, month) => new Date(year, (month + 1), 0).getDate();

const nextOccurenceUnixBuilder = (year, anyDateUnix) => {
  const startDateValue = new Date(anyDateUnix * 1000);
  startDateValue.setUTCFullYear(year);
  return Math.round(startDateValue.getTime() / 1000);
};

const nextEvent = (applet, acc = 0) => {
  const { startDate, recurrence: { recurrenceRule: { interval } } } = applet;
  const [day, month, year] = datePartsGrabber(startDate);
  if (day <= numberOfDaysInMonth((year + acc), month)) {
    const nextOccurrenceUnix = nextOccurenceUnixBuilder((year + acc), startDate);
    if (nextOccurrenceUnix >= Math.round(Date.now() / 1000)) return nextOccurrenceUnix;
  }
  return nextEvent(applet, acc + interval);
};

export { datePartsGrabber, nextOccurenceUnixBuilder };
export default nextEvent;
