import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/main';
import createURL from '../../../utils/create-url'; import ActionView from '../../../components/actions-view';
import DescriptionView from '../../../components/description-view';
import NameView from '../../../components/name-view';
import BannerView from '../../../components/banner-view';
import CustomPropsView from '../../../components/custom-props-view';

function MainView({
  applet, edit, classes, id,
}) {
  const {
    name, description, image, customProperties,
  } = applet;
  return (
    <Card className={classes.root}>
      <BannerView image={image} edit={edit} editURL={`/${createURL(applet)}/~edit/image`} />
      <CardContent className={classes.cardContent}>
        <NameView name={name} edit={edit} editURL={`/${createURL(applet)}/~edit/name`} />
        {(description || edit) && <Divider className={classes.divider} />}
        <DescriptionView description={description} edit={edit} editURL={`/${createURL(applet)}/~edit/description`} />
        <CustomPropsView
          values={customProperties}
          editURL={`/${createURL(applet)}/~edit/customProperties`}
          edit={edit}
        />
        <ActionView applet={applet} id={id} editURL={`/${createURL(applet)}/~edit/actions`} edit={edit} />
      </CardContent>
    </Card>
  );
}

MainView.propTypes = {
  id: PropTypes.string.isRequired,
  applet: PropTypes.shape({
    appletId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
  }),
  edit: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
};

MainView.defaultProps = {
  applet: {
    name: '',
    description: '',
  },
  edit: false,
};

export default withStyles(styles)(MainView);
