const styles = {
  root: {
    position: 'relative',
    '&:hover $content': {
      opacity: '1',
    },
  },

  content: {
    padding: '8px',
    flexDirection: 'column',
    position: 'absolute',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    opacity: '0',
    transition: 'all .35s',
  },
  imageContainer: {
    textDecoration: 'none',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: 'auto',
    minWidth: '200px',
    width: '100%',
    '@media (max-width: 400px)': {
      height: '45vw',
      minWidth: '45vw',
    },
    backgroundColor: '#FFFFFF',
    objectFit: 'cover',
  },
  imageAlt: {
    height: '200px',
    minWidth: '200px',
    width: '100%',
    padding: '50px',
    color: 'grey',
    '@media (max-width: 400px)': {
      height: '45vw',
      minWidth: '45vw',
    },
    backgroundColor: '#FFFFFF',
  },

  singleLine: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

export default styles;
