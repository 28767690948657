import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import getAppletPath from 'packages/extensions/utils/create-url';
import ListCardBase from 'packages/view-widgets/list-card-item/base';
import styles from 'packages/extensions/styles/views/list-card-item';
import { useAppConfig } from 'helper-hooks';

function ListCard({ applet, classes, ...otherProps }) {
  const { name, image } = applet;
  const { appName } = useAppConfig();
  const isLinkist = appName.toLowerCase() === 'linkist';
  return (
    <ListCardBase
      title={name}
      detail={isLinkist && getAppletPath(applet)}
      imageURL={image && image.contentURL}
      classes={classes}
      {...otherProps}
    />
  );
}

ListCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ListCard);
