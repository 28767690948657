import nowInTimeRange from '../now-in-time-range';

const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const isInDays = (today, shortDays) => shortDays.includes(today);

const openStatus = (openingHours) => {
  if (!openingHours || !openingHours.length) return 'Closed';
  const allOpenDays = openingHours.reduce((acc, { days }) => [...acc, ...days], []);
  const { timezoneOffset } = openingHours[0];
  const nowLocal = new Date(Date.now() - (timezoneOffset * 60 * 1000));
  const today = weekDays[nowLocal.getUTCDay()];
  if (!isInDays(today, allOpenDays)) return 'Closed';
  const openingHoursCoveringToday = openingHours.filter(({ days }) => days.includes(today))[0];
  const isCurrentlyOpen = Boolean(openingHoursCoveringToday.openHours
    .filter(hours => nowInTimeRange({ ...hours, timezoneOffset })).length);
  return isCurrentlyOpen ? 'Open now' : 'Closed';
};

export const isOpen = openingHours => openStatus(openingHours) === 'Open now';
export default openStatus;
