const recurrencePropertyConfig = {
  name: 'Event Recurrence',
  edit: {
    form: 'event-recurrence',
    label: 'Edit Recurrence Details',
    handler: value => value,
    onDelete: (deleteField) => deleteField(),
  },
};
export default recurrencePropertyConfig;
