import { createStyles } from '@mui/styles';

const styles = createStyles({
  image: {
    objectFit: 'contain',
    display: 'block',
    maxHeight: '320px',
    maxWidth: '100%',
    minWidth: '0',
    alignSelf: 'baseline',
    flexGrow: 1,
  },
  imageModal: {
    margin: 'auto',
    alignSelf: 'center',
    maxHeight: '85vh',
    maxWidth: '85vw',
    outline: 'none',
  },
  openingHoursContainer: {
    marginTop: '15px',
    marginBottom: '10px',
    display: 'grid',
    gridRowGap: '20px',
  },
  modal: {
    display: 'flex',
  },
  divider: {
    margin: '10px 0',
  },
  root: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '200px',
    alignContent: 'baseline',
  },
  content: {
    flexGrow: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    minWidth: '180px',
    width: 'min-content',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '0',
  },
  logo: {
    height: '1em',
    width: '1em',
    flexShrink: '1',
    margin: '0 0.5em 0 0',
  },
  subheader: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
