const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    height: '300px',
    maxWidth: '300px',
  },
  title: {
    fontSize: '1.2em',
  },
  cardHeader: {
    padding: '0.7em',
    flexGrow: 0,
  },
  detail: {
    flexGrow: 1,
    minHeight: 0,
  },
  description: {
    padding: '8px',
    textDecoration: 'none',
    overflow: 'hidden',
    backgroundImage: 'url(/yote-fade.svg)',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  subheader: {
    padding: '0.7em',
    textDecoration: 'none',
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1em',
    color: '#A6A4A4',
  },

  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    objectFit: 'cover',
  },
  logo: {
    height: '1em',
    width: '1em',
    margin: '0 0.5em 0 0',
  },

};

export default styles;
