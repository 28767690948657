import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/card';
import withOpenStatus from '../../utils/with-place-open-status';
import OpeningHoursView from '../opening-hours-view';
import CardBase from '../../../../view-widgets/card/base';
import placeOpenOrClosed from '../../utils/place-open-or-closed';

function CardView({
  applet: {
    name, description, openingHours, image, actions, customProperties,
  }, url, classes, ...otherProps
}) {
  const OpenStatus = withOpenStatus(({ openStatus }) => openStatus);
  const NextOpening = withOpenStatus(({ nextOpening }) => nextOpening);

  const openStatus = openingHours && <OpenStatus openingHours={openingHours} />;
  const nextOpening = openingHours && <NextOpening openingHours={openingHours} />;

  const daysInOpeningHours = openingHours.reduce((acc, { days }) => [...acc, ...days], []);
  const daysNotInOpeningHours = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
    .filter(day => !daysInOpeningHours.includes(day));
  const secondary = (
    <div className={classes.openingHoursContainer}>
      {openingHours.map((hours, index) => {
        const reactKey = `open-hours-${index}${url}`;
        return <OpeningHoursView key={reactKey} openingHours={hours} />;
      })}
      {daysNotInOpeningHours.length !== 0
        && <OpeningHoursView openingHours={{ days: daysNotInOpeningHours }} />}
      <Divider />
      {description}
    </div>
  );
  return (
    <CardBase
      image={image}
      title={name}
      subheader={placeOpenOrClosed(openingHours) === 'Open now' ? openStatus : nextOpening}
      description={secondary}
      customProperties={customProperties}
      actions={actions}
      url={url}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    openingHours: PropTypes.arrayOf(PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.string),
      openingHours: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      })),
      timezoneOffset: PropTypes.number,
    })),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
  }).isRequired,
  url: PropTypes.string,
};
CardView.defaultProps = {
  url: undefined,
};

export default withStyles(styles)(CardView);
