import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import DefaultAvatar from '../../avatar';
import ListItemBaseView from '../../../../view-widgets/list-item/base';

function ListItemView({ applet, ...otherProps }) {
  const {
    name, address, logo, image,
  } = applet;

  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && <DefaultAvatar />}
    </Avatar>
  );

  return (
    <ListItemBaseView
      primary={name}
      secondary={address}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};

ListItemView.defaultProps = {
  applet: {
    name: undefined,
    address: undefined,
  },
};
export default ListItemView;
