import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import {
  Card, CardHeader, Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Image from 'components/image';
import defaultStyles from 'packages/extensions/styles/views/mini-card';

function MiniCardBase({
  imageURL, logoURL, title, url, classes, viewProps, description, subheader,
}) {
  let cardComp = 'div';
  let target = '';
  let rel = '';
  if (url && url.startsWith('/')) {
    cardComp = Link;
  } else if (url) {
    cardComp = 'a';
    if (!url.startsWith('tel:')) {
      target = '_blank';
      rel = 'noopener';
    }
  }
  return (
    <Card
      component={cardComp}
      href={url}
      rel={rel}
      to={url}
      target={target}
      className={classes.root}
      elevation={viewProps.elevation}
      square={viewProps.square}
    >
      <CardHeader
        title={title}
        className={classes.cardHeader}
        titleTypographyProps={{ className: classes.title }}
      />
      {imageURL ? (
        <Image
          src={imageURL}
          className={`test-mini-card-image ${classes.image} ${classes.detail}`}
          alt={title}
        />
      )
        : (
          <Typography className={`${classes.description} ${classes.detail}`}>
            {description}
          </Typography>
        )}
      <div className={classes.subheader}>
        {logoURL && <Image src={logoURL} className={`test-mini-card-logo ${classes.logo}`} alt="" />}
        <Typography variant="caption" noWrap>
          {subheader}
        </Typography>
      </div>
    </Card>
  );
}

MiniCardBase.propTypes = {
  imageURL: PropTypes.string,
  logoURL: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  subheader: PropTypes.node,
  url: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  viewProps: PropTypes.shape({
    square: PropTypes.bool,
    elevation: PropTypes.number,
  }),
};

MiniCardBase.defaultProps = {
  imageURL: undefined,
  logoURL: undefined,
  url: undefined,
  subheader: '',
  description: '',
  viewProps: {},
};

export default withStyles(defaultStyles)(MiniCardBase);
