export default {
  name: 'Link Text',
  edit: {
    form: 'name-input',
    label: 'Edit Link Text',
    handler: value => value,
  },
  linkedProperties: [
    {
      name: 'actions',
      transform: (currentActions, newLinkText) => {
        try {
          const newActions = [...currentActions];
          newActions[0].name = newLinkText;
          return newActions;
        } catch {
          return [];
        }
      },
    },
  ],
};
