import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import DefaultAvatar from '../../avatar';
import withOpenStatus from '../../utils/with-place-open-status';
import ListItemBaseView from '../../../../view-widgets/list-item/base';
import { isOpen } from '../../utils/place-open-or-closed';

function ListItemView({ applet, ...otherProps }) {
  const { image, logo, openingHours } = applet;
  const OpenStatus = withOpenStatus(({ openStatus }) => <span>{openStatus}</span>);
  const NextOpening = withOpenStatus(({ nextOpening }) => <span>{nextOpening}</span>);
  const currentlyOpen = isOpen(openingHours);

  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && <DefaultAvatar />}
    </Avatar>
  );
  return (
    <ListItemBaseView
      primary={<OpenStatus openingHours={openingHours} />}
      secondary={currentlyOpen ? applet.name : <NextOpening openingHours={openingHours} />}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    openingHours: PropTypes.arrayOf(PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.string),
      openingHours: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      })),
      timezoneOffset: PropTypes.number,
    })),
  }),
};

ListItemView.defaultProps = {
  applet: {
    name: undefined,
  },
};
export default ListItemView;
