import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutoComplete from 'react-places-autocomplete';
import { TextField, Typography, Divider } from '@mui/material';
import withContainer from './container';

const styles = {
  suggestionItemActive: { backgroundColor: '#fafafa', cursor: 'pointer' },
  suggestionItemDeactive: { backgroundColor: '#ffffff', cursor: 'pointer' },
  suggestions: {
    marginTop: '5px',
    marginBottom: '5px',
  },
};

function InnerFunc({
  getInputProps, suggestions, getSuggestionItemProps,
}) {
  return (
    <div>
      <TextField
        variant="standard"
        {...getInputProps({ placeholder: 'Search Places...' })}
        fullWidth
      />
      <div className="autocomplete-dropdown-container">
        {suggestions.map((suggestion, index) => {
          const key = `${suggestion.description}-${index}`;
          const className = suggestion.active ? 'suggestionItemActive' : 'suggestionItemDeactive';
          return (
            <div style={styles.suggestions} key={key}>
              <Typography
                component="span"
                variant="body1"
                {...getSuggestionItemProps(
                  { ...suggestion, active: undefined },
                  { className, styles },
                )}
              >
                {suggestion.description}
              </Typography>
              <Divider />
            </div>
          );
        })}
      </div>
    </div>
  );
}

InnerFunc.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
};

function PlacesAutoCompleteForm({
  value, setValue, onSelect, isScriptLoaded,
}) {
  if (isScriptLoaded) {
    return (
      <PlacesAutoComplete
        value={value}
        onChange={setValue}
        onSelect={onSelect}
      >
        {InnerFunc}
      </PlacesAutoComplete>
    );
  }
  return null;
}
PlacesAutoCompleteForm.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isScriptLoaded: PropTypes.bool.isRequired,
};

PlacesAutoCompleteForm.defaultProps = {
  value: undefined,
};

export { InnerFunc, PlacesAutoCompleteForm };
export default withContainer(PlacesAutoCompleteForm);
