/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Divider,
  Typography,
  Avatar,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'packages/use-navigation/navigation-context';
import ActionButton from 'packages/view-widgets/action-button';
import QVList from 'packages/view-widgets/quantitative-view/list';
import styles from 'packages/extensions/styles/views/card';
import Image from 'packages/view-widgets/image';

function CardBase({
  customProperties, title, description, subheader, image, logo,
  actions, viewProps, classes, leftAction, options, url,
}) {
  let avatar;
  if (logo && logo.contentURL) {
    avatar = <Avatar alt={title} src={logo.contentURL} />;
  }
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => { setShowModal(!showModal); }, [showModal]);
  let cardComp = 'div';
  let target = '';
  let rel = '';
  if (url && url.startsWith('/')) {
    cardComp = Link;
  } else if (url) {
    cardComp = 'a';
    if (!url.startsWith('tel:')) {
      target = '_blank';
      rel = 'noopener';
    }
  }

  return (
    <Card
      className={classes.root}
      elevation={viewProps.elevation}
      square={viewProps.square}
    >
      {(image && image.contentURL) && (
        <>
          <Modal open={showModal} onClose={toggleModal} className={classes.modal}>
            <Image
              src={image.contentURL}
              alt={title}
              className={classes.imageModal}
            />
          </Modal>
          <Image
            onClick={toggleModal}
            src={image.contentURL}
            alt={title}
            className={classes.image}
          />
        </>
      )}
      <CardContent className={classes.content} style={{ padding: 16 }}>
        <CardHeader
          component={cardComp}
          href={url}
          rel={rel}
          to={url}
          target={target}
          avatar={avatar}
          style={{ padding: 0 }}
          title={title}
          titleTypographyProps={{ style: { fontSize: '1.1rem', fontWeight: 500, lineHeight: 1 } }}
          subheaderTypographyProps={{ variant: 'caption' }}
          subheader={subheader}
          action={options}
        />
        {
          description
          && (
            <>
              <Divider style={{ margin: '8px 0' }} variant="middle" />
              <Typography component="div" variant="body2">
                {description}
              </Typography>
            </>
          )
        }
        {customProperties && customProperties.length > 0 && <QVList values={customProperties} />}
        <div style={{ flexGrow: 1 }} />
        {
          (actions || leftAction)
          && (
            <CardActions className={classes.actions}>
              <div>
                {leftAction}
              </div>
              <div>
                {actions && actions.map((action, i) => {
                  const uniqueKey = `${action.toString()}-${i}`;
                  return <ActionButton variant="text" action={action} key={uniqueKey} />;
                })}
              </div>
            </CardActions>
          )
        }
      </CardContent>
    </Card>
  );
}

CardBase.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  subheader: PropTypes.node,
  url: PropTypes.string,
  logo: PropTypes.shape({
    contentURL: PropTypes.string,
  }),
  image: PropTypes.shape({
    contentURL: PropTypes.string,
  }),
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    target: PropTypes.string,
  })),
  leftAction: PropTypes.node,
  options: PropTypes.node,
  viewProps: PropTypes.shape({
    elevation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    square: PropTypes.bool,
  }),
  customProperties: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.node,
    unit: PropTypes.node,
    label: PropTypes.node,
  })),
  classes: PropTypes.shape({}),
};

CardBase.defaultProps = {
  customProperties: undefined,
  url: undefined,
  subheader: undefined,
  title: undefined,
  description: undefined,
  image: undefined,
  logo: undefined,
  actions: undefined,
  leftAction: null,
  options: null,

  viewProps: {},
  classes: {},
};

export default withStyles(styles)(CardBase);
