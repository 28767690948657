import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import ListItemBaseView from '../../../../view-widgets/list-item/base';

function ListItemView({ applet, ...otherProps }) {
  const { name, image, logo } = applet;

  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && applet.name && applet.name[0].toUpperCase()}
    </Avatar>
  );

  return (
    <ListItemBaseView
      primary={name}
      secondary={applet._meta.username && `@${applet._meta.username}`}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    _meta: PropTypes.shape({
      username: PropTypes.string,
    }),
  }),
};

ListItemView.defaultProps = {
  applet: {
    description: '',
  },
};

export default ListItemView;
