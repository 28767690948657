import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import Edit from '@mui/icons-material/Edit';
import { withStyles } from '@mui/styles';
import { Typography, IconButton } from '@mui/material';

const styles = {
  rootTypo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // flexGrow: 1,
  },
  link: {
    marginRight: 8,
    marginLeft: 8,
  },
};

function NameView({
  name, edit, editURL, classes,
}) {
  return (
    <Typography variant="h6" className={classes.rootTypo}>
      {name}
      {
      edit
      && (
      <Link to={editURL} href={editURL} className={classes.link}>
        <IconButton size="large">
          <Edit />
        </IconButton>
      </Link>
      )
    }
    </Typography>
  );
}

NameView.propTypes = {
  name: PropTypes.string.isRequired,
  editURL: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
};

NameView.defaultProps = {
  edit: false,
};

export default withStyles(styles)(NameView);
