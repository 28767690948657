const datePartsGrabber = (timeStamp) => {
  const dateValue = new Date(timeStamp * 1000);
  return [
    dateValue.getUTCDate(),
    dateValue.getUTCMonth(),
    dateValue.getUTCFullYear(),
  ];
};

const monthYearIncrementer = ([month, year], interval) => {
  const newMonth = (interval % 12) + month;
  const newYear = Math.floor(interval / 12) + year;
  return [newMonth, newYear];
};

const isDateContained = ([month, year], dayOfMonth) => {
  const numberOfDaysInMonth = new Date(year, (month + 1), 0).getDate();
  return dayOfMonth <= numberOfDaysInMonth;
};

const nextOccurenceUnixBuilder = ([month, year], anyDateUnix) => {
  const startDateValue = new Date(anyDateUnix * 1000);
  startDateValue.setUTCFullYear(year);
  startDateValue.setUTCMonth(month);
  return Math.round(startDateValue.getTime() / 1000);
};

const isGreaterThanNow = unix => unix >= Math.round(Date.now() / 1000);

const nextEvent = (applet, acc = 0) => {
  const { startDate, recurrence: { recurrenceRule: { interval } } } = applet;
  const dateParts = datePartsGrabber(startDate);
  const [day, ...monthFullYear] = dateParts;
  const incrementedMonthYear = monthYearIncrementer(monthFullYear, (acc + interval));
  const dateContained = isDateContained(incrementedMonthYear, day);
  const nextDate = nextOccurenceUnixBuilder(incrementedMonthYear, startDate);
  if (dateContained && isGreaterThanNow(nextDate)) {
    return nextDate;
  }

  return nextEvent(applet, (acc + interval));
};

export {
  datePartsGrabber,
  monthYearIncrementer,
  isDateContained,
  nextOccurenceUnixBuilder,
};
export default nextEvent;
