import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import CharacterCounter from './character-counter';

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
  },

  textField: {
    color: '#00BCD4',
  },
};

function DescriptionInput({
  value, onChange, inputProps, classes, ...otherProps
}) {
  return (
    <div className={classes.container}>
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        {...otherProps}
        fullWidth
        className={classes.textField}
      />
      <Typography variant="caption" gutterBottom align="left">
        <CharacterCounter text={value} max={inputProps.maxLength} />
      </Typography>
    </div>
  );
}

DescriptionInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  inputProps: PropTypes.shape({
    maxLength: PropTypes.number,
  }),
  classes: PropTypes.shape({}).isRequired,
};

DescriptionInput.defaultProps = {
  value: '',
  label: 'Description',
  multiline: true,
  rows: 4,
  inputProps: {
    maxLength: 300,
  },
};
export default withStyles(styles)(DescriptionInput);
