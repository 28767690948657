import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Down from '@mui/icons-material/ArrowDropDown';
import {
  Button, Menu, MenuItem, ListItemText,
} from '@mui/material';
import currencies from './currencies';

function Component({ onChange, value }) {
  const [anchorEl, setAnchor] = useState(null);
  const open = useCallback(({ target }) => {
    setAnchor(target);
  }, []);
  const close = useCallback(() => {
    setAnchor(null);
  }, []);
  return (
    <div>
      <Button onClick={open} size="small">
        {value && currencies[value].code}
        <Down />
      </Button>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={close}>
        {
          Object.keys(currencies).map(currency => (
            <MenuItem
              key={currency}
              onClick={() => {
                close();
                onChange(currency);
              }}
            >
              <ListItemText
                primary={currencies[currency].name_plural}
                secondary={currencies[currency].code}
              />
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

Component.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.keys(currencies)),
};
Component.defaultProps = {
  value: undefined,
};

export default Component;
