import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import ListItemBaseView from '../../../../view-widgets/list-item/base';
import DefaultAvatar from '../../avatar';

function ListItemView({
  applet: {
    name, description, image, logo,
  }, ...otherProps
}) {
  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && <DefaultAvatar />}
    </Avatar>
  );
  return (
    <ListItemBaseView
      primary={name}
      secondary={description}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
};

ListItemView.defaultProps = {
  applet: {
    description: '',
  },
};

export default ListItemView;
