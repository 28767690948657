import React from 'react';
import PropTypes from 'prop-types';

function CharacterCounter({ text, max }) {
  return (
    <span>
      {text ? text.length : 0}
      /
      {max}
    </span>
  );
}

CharacterCounter.propTypes = {
  max: PropTypes.number.isRequired,
  text: PropTypes.string,
};

CharacterCounter.defaultProps = {
  text: '',
};
export default CharacterCounter;
