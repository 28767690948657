import React from 'react';
import PropTypes from 'prop-types';
import PriceView from '../../../../view-widgets/price';
import Base from '../../../../view-widgets/image-view/base';

function ImageViewer({
  applet: {
    image,
    name,
    priceSpecification,
  },
  ...otherProps
}) {
  return (
    <Base
      imageURL={image && image.contentURL}
      primary={name}
      secondary={
      priceSpecification
      && priceSpecification.price
      && <PriceView value={priceSpecification} />
    }
      {...otherProps}
    />
  );
}
ImageViewer.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    priceSpecification: PropTypes.shape({
      price: PropTypes.number,
      priceCurrency: PropTypes.string,
    }),
  }).isRequired,
};

export default ImageViewer;
