import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { useAppConfig } from 'helper-hooks';
import getAppletPath from 'packages/extensions/utils/create-url';
import ListItemBaseView from 'packages/view-widgets/list-item/base';
import CollectionAvatar from '@mui/icons-material/CollectionsBookmark';

function getAvatarFallBack(applet) {
  return applet.name && applet.name[0].toUpperCase();
}
export function ListItemView({ applet, ...otherProps }) {
  const {
    name, description, image, logo,
  } = applet;
  const { appName } = useAppConfig();
  const isLinkist = appName.toLowerCase() === 'linkist';
  const avatarSrc = (logo && logo.contentURL)
  || (image && image.contentURL)
  || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {isLinkist && !avatarSrc && <CollectionAvatar />}
      {!isLinkist && !avatarSrc && getAvatarFallBack(applet)}
    </Avatar>
  );

  return (
    <ListItemBaseView
      primary={name}
      secondary={isLinkist ? getAppletPath(applet) : description}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};

ListItemView.defaultProps = {
  applet: {
    name: undefined,
    description: undefined,
  },
};
export default ListItemView;
