import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import WeekDayPicker from '../../week-day-picker';
import TimeRangeForm from '../../time-range';
import withContainer from './container';

const styles = {
  timeRangeForm: {
    marginTop: 16,
  },
};

function OpenHoursForm({
  value,
  dayExclusions,
  onChange,
  newOpenHoursForm,
  toggleNewOpenHoursForm,
  classes,
}) {
  return (
    <div>
      <WeekDayPicker
        value={value && value.days}
        exclusions={dayExclusions}
        onChange={(newDays) => {
          onChange({
            days: newDays,
            openHours: value && value.openHours,
          });
        }}
      />
      {
        value && value.openHours && value.openHours.map((openHourz, index) => {
          const uniqueKey = index;
          return (
            <div
              className={classes.timeRangeForm}
              key={uniqueKey}
            >
              <TimeRangeForm
                label="Open Hours"
                value={openHourz}
                onChange={(receivedOpenHours) => {
                  const newOpenHours = [...value.openHours];
                  newOpenHours[index] = receivedOpenHours;
                  onChange({
                    days: value && value.days,
                    openHours: newOpenHours,
                  });
                }}
              />
            </div>
          );
        })
      }
      {
        newOpenHoursForm
          && (
          <div className={classes.timeRangeForm}>
            <TimeRangeForm
              label="Open Hours"
              onChange={(receivedOpenHours) => {
                const newOpenHours = value && value.openHours ? [...value.openHours] : [];
                newOpenHours.push(receivedOpenHours);
                toggleNewOpenHoursForm();
                onChange({
                  days: value && value.days,
                  openHours: newOpenHours,
                });
              }}
            />
          </div>
          )
      }
      <IconButton
        onClick={toggleNewOpenHoursForm}
        disabled={newOpenHoursForm}
        color="primary"
        size="large"
      >
        <AddIcon />
      </IconButton>
    </div>
  );
}

OpenHoursForm.propTypes = {
  value: PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.string),
    openHours: PropTypes.arrayOf(PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    })),
  }),
  dayExclusions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  newOpenHoursForm: PropTypes.bool.isRequired,
  toggleNewOpenHoursForm: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

OpenHoursForm.defaultProps = {
  value: undefined,
};

export default withStyles(styles)(withContainer(OpenHoursForm));
