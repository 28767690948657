import getAppletPath from '../../../utils/create-url';

export default {
  name: 'Opening Hours',
  edit: {
    form: 'applet-input',
    label: 'Edit Opening Hours Applet',
    handler: value => value,
    getAltEditLink: (parent) => {
      const searchParams = new URLSearchParams();
      searchParams.set('property', 'openingHours');
      const pathname = `/${getAppletPath(parent)}/~create/OpeningHours`;
      return {
        label: 'Create New',
        pathname,
        search: `?${searchParams.toString()}`,
        state: {
          presets: {
            name: 'Opening Hours',
            description: `${parent.name} Opening Hours`,
          },
        },
      };
    },
  },
};
