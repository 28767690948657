import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const style = {
  div: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },
  textField: {
    color: '#00BCD4',
  },
};

function TelephoneField({ maxLength, ...props }) {
  return (
    <div style={style.div}>
      <TextField
        variant="standard"
        style={style.textField}
        placeholder="+ 256 700 000000"
        {...props}
        inputProps={{ maxLength }}
        type="tel"
        fullWidth
      />
    </div>
  );
}

TelephoneField.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
};

TelephoneField.defaultProps = {
  maxLength: 50,
};

export default TelephoneField;
