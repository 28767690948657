// tslint:disable-next-line: interface-name
export interface Suggestion {
  value: string;
  label: string;
}
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*(\s|$)?/;
const usernamePatterns = [
  ['Facebook', /^[A-z0-9_\-.]+/, 'https://facebook.com/', ''],
  ['Instagram', /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/, 'https://instagram.com/', ''],
  ['Twitter', /^[A-z0-9_]+/, 'https://twitter.com/', ''],
  ['Github', /^[A-z0-9_-]+/, 'https://github.com/', ''],
  ['LinkedIn', /^[A-z0-9_-]+/, 'https://linkedin.com/in/', ''],
  ['Telegram', /[0-9A-Za-z_]+/, 'https://t.me/', ''],
];

function phoneNumbers(value: string) {
  const match = value.match(phoneRegex);
  if (match) {
    const phoneNumber = match[0].replace(/\s$/g, '');
    const extra = value.replace(match[0], '');
    return [
      { label: `Call ${phoneNumber}`, value: `tel:${phoneNumber}` },
      { label: `Text ${phoneNumber}`, value: `sms:${phoneNumber}` },
      {
        label: `Whatsapp ${phoneNumber}`,
        value: `https://wa.me/${phoneNumber.replace(/^\+/, '')}${extra ? `?text=${extra}` : ''}`,
      },
    ];
  } return [];
}
function socialMedia(value: string): Suggestion[] {
  if (value.startsWith('@')) {
    const val = value.trim().replace(/^@/, '');
    const results = usernamePatterns.map((config) => {
      const [name, pattern, prefix, suffix] = config as [string, RegExp, string, string];
      const username = pattern.exec(val);
      if (username) {
        return { label: `${username} on ${name}`, value: `${prefix}${username}${suffix}` };
      } return { label: '', value: '' };
    }).filter(({ label }) => label !== '');
    return results;
  }
  return [];
}

function email(value: string) {
  function validateEmail(address: string) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(address).toLowerCase());
  }
  if (validateEmail(value)) {
    return [{ label: `Email ${value}`, value: `mailto:${value}` }];
  }
  return [];
}

function mobileMoney(value: string) {
  const match = /^\+?256([0-9]{9})$/g.exec(value);
  if (match) {
    const ugandanNumber = match.input.replace(/\+?256/, '0');
    return [{
      label: `Send Mobile Money to ${ugandanNumber}`,
      value: `tel:*185*1*1*${ugandanNumber}*<label:Amount;type:number>#`,
    }];
  } return [];
}

const getSuggestions = (value: string): Suggestion[] => [
  ...phoneNumbers(value),
  ...socialMedia(value),
  ...email(value),
  ...mobileMoney(value),
];
export default getSuggestions;
