import React from 'react';
import PropTypes from 'prop-types';
import OpenHoursForm from '../../../../forms/open-hours';

export function Form(props) {
  const { isValidCallback, onChange, value } = props;
  if (isValidCallback) {
    if (value && value.openingHours && value.openingHours.length) {
      isValidCallback(true);
    } else {
      isValidCallback(false);
    }
  }

  function onUpdate(val) {
    onChange({
      name: 'Opening Hours',
      ...value,
      openingHours: val,
    });
  }
  return (
    <OpenHoursForm
      value={value && value.openingHours}
      onChange={(v) => { onUpdate(v); }}
    />
  );
}

Form.propTypes = {
  value: PropTypes.shape({
    openingHours: PropTypes.arrayOf(PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.string),
      openHours: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      })),
    })),
  }),
  isValidCallback: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

Form.defaultProps = {
  value: undefined,
  isValidCallback: null,
};

export default Form;
