import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import setPropTypes from 'recompose/setPropTypes';
import PropTypes from 'prop-types';

const onValueChange = ({
  value, setValue, onChange, showEmptyForm,
}) => (valueUpdate, index) => {
  const newValue = [...value];
  if (typeof index === 'number' && index >= 0) {
    newValue[index] = valueUpdate;
  } else {
    newValue.push(valueUpdate);
    showEmptyForm(false);
  }
  setValue(newValue);
  onChange(newValue);
};

const onDelete = ({
  value, setValue, onChange, showEmptyForm,
}) => (index) => {
  if (typeof index === 'number') {
    const newValue = [...value];
    newValue.splice(index, 1);
    setValue(newValue);
    onChange(newValue);
  } else {
    showEmptyForm(false);
  }
};

const addNewForm = ({ showEmptyForm }) => () => {
  showEmptyForm(true);
};

export default compose(
  setPropTypes({
    onChange: PropTypes.func.isRequired,
  }),
  withState('value', 'setValue', ({ value }) => value || []),
  withState('emptyForm', 'showEmptyForm', ({ value }) => (!(value && value.length))),
  withHandlers({
    onChange: onValueChange,
    onDelete,
    addNewForm,
  }),
);
