import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Edit from 'react-icons/lib/md/create';
import Add from 'react-icons/lib/md/add';
import { Link } from 'packages/use-navigation/navigation-context';
import {
  Card, CardContent, Divider, Typography, IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/main';
import createURL from '../../../utils/create-url'; import ActionView from '../../../components/actions-view';
import DescriptionView from '../../../components/description-view';
import NameView from '../../../components/name-view';
import BannerView from '../../../components/banner-view';
import CustomPropertiesView from '../../../components/custom-props-view';

function Main({
  applet, edit, classes, id,
}) {
  const {
    name, url, description, image, customProperties,
  } = applet;
  return (
    <Card className={classes.root}>
      <BannerView image={image} edit={edit} editURL={`/${createURL(applet)}/~edit/image`} />
      <CardContent className={classes.cardContent}>
        <NameView name={name} edit={edit} editURL={`/${createURL(applet)}/~edit/name`} />
        {(url || edit)
        && (
        <>
          {edit && <Divider className={classes.divider} />}
          <Typography gutterBottom={!!description} variant="caption" className={classes.url}>
            {url && url}
            {
                edit
                && (
                <Link to={`/${createURL(applet)}/~edit/url`} href={`/${createURL(applet)}/~edit/url`} className={classes.link}>
                  {
                    url
                      ? (
                        <IconButton size="large">
                          <Edit />
                        </IconButton>
                      )
                      : (
                        <span className={classes.linkInner}>
                          <Add />

                          Add Url
                        </span>
                      )
                  }
                </Link>
                )
              }
          </Typography>
        </>
        )}
        {(description || edit) && <Divider className={classes.divider} />}
        <DescriptionView description={description} edit={edit} editURL={`/${createURL(applet)}/~edit/description`} />
        <CustomPropertiesView
          values={customProperties}
          editURL={`/${createURL(applet)}/~edit/customProperties`}
          edit={edit}
        />
        <ActionView applet={applet} id={id} editURL={`/${createURL(applet)}/~edit/actions`} edit={edit} />
      </CardContent>
    </Card>
  );
}

Main.propTypes = {
  id: PropTypes.string.isRequired,
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
  }),
  edit: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired,
};

Main.defaultProps = {
  applet: {
    url: '',
    description: '',
  },
  edit: false,
};

export default withStyles(styles)(Main);
