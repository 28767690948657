import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Portal,
  Card,
  CardActions,
  LinearProgress,
  FormControl,
  FormHelperText,
  Collapse,
  Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import LinkInput from 'packages/forms/link-input';
import NameInput from 'packages/forms/name-input';
import DescriptionInput from 'packages/forms/description-input';
import parseURL from './parse-url';
import describeLink from './parse-url/describe-link';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    marginTop: 16,
  },
};

function BasicForm({
  value, onChange, isValidCallback, actionArea, classes,
}) {
  const [doneFirstParse, setDoneFirstParse] = useState(false);
  const [originalValue] = useState(value);
  const [name, setName] = useState(value.name);
  const [description, setDescription] = useState(value.description);
  const [url, setURL] = useState(value.url);
  const initialLinkText = (value.actions && value.actions[0] && value.actions[0].name) || (!!name && 'Visit');
  const [linkText, setLinkText] = useState(initialLinkText);
  const [logo, setLogo] = useState();
  const [image, setImage] = useState();

  const [nameEdited, setNameEdited] = useState(false);
  const [descriptionEdited, setDescriptionEdited] = useState(false);
  const [linkTextEdited, setLinkTextEdited] = useState(false);

  const [urlLoadingFailure, setURLLoadingFailure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false || !!name);

  useEffect(() => {
    if (isValidCallback) {
      if (url && name) {
        isValidCallback(true);
      } else {
        isValidCallback(false);
      }
    }
  }, [isValidCallback, name, url]);

  useEffect(() => {
    const updatedApplet = {
      ...originalValue,
      name,
      description,
      url,
      actions: [{
        target: url,
        name: linkText,
      }],
    };
    if (image) {
      updatedApplet.image = image;
    }
    if (logo) {
      updatedApplet.logo = logo;
    }
    onChange(updatedApplet);
  }, [description, image, linkText, logo, name, onChange, originalValue, url]);

  const handleURLMetaChecks = (link) => {
    setURL(link);
    if ((link && url !== link) || (link && !doneFirstParse)) {
      setLoading(true);
      setURLLoadingFailure(false);
      const describedLink = describeLink(link);
      parseURL(link).then((result) => {
        setDoneFirstParse(true);
        setLoading(false);
        if (result && result.data) {
          const { data } = result;

          setName(nameEdited ? name : data.title);
          setDescription(descriptionEdited ? description : data.description);
          setLinkText(linkTextEdited ? linkText : describedLink.linkText);

          if (result.data.logo) {
            setLogo({ contentURL: result.data.logo });
          }
          if (result.data.image) {
            setImage({ contentURL: result.data.image });
          }
          setExpanded(true);
        }
      }).catch(() => {
        setDoneFirstParse(true);
        setDescription(describedLink.description);
        setLinkText(describedLink.linkText);
        setName(describedLink.title);
        setLoading(false);
        setURLLoadingFailure(true);
        setExpanded(true);
      });
    }
  };

  useEffect(() => {
    if (value?.url && !doneFirstParse) {
      handleURLMetaChecks(value.url);
    }
  }, []);

  const onURLChanged = useCallback(
    handleURLMetaChecks,
    [description, descriptionEdited, doneFirstParse,
      linkText, linkTextEdited, name, nameEdited, url],
  );

  const onNameChanged = useCallback(({ target: { value: val } }) => {
    setName(val);
    setNameEdited(true);
  }, []);

  const onDescriptionChanged = useCallback(({ target: { value: val } }) => {
    setDescription(val);
    setDescriptionEdited(true);
  }, []);

  const onLinkTextChanged = useCallback(({ target: { value: val } }) => {
    setLinkText(val);
    setLinkTextEdited(true);
  }, []);
  return (
    <Card elevation={0}>
      <div className={classes.container}>
        <FormControl variant="standard" error={urlLoadingFailure}>
          <LinkInput
            label="Link"
            placeholder="Link, Phone Number or @username"
            value={url}
            required
            onChange={onURLChanged}
          />
          {
            urlLoadingFailure && url && !loading
            && (
              <FormHelperText>
                Could not connect to website, try again or enter details below
              </FormHelperText>
            )
          }
        </FormControl>
        {loading && <LinearProgress style={{ position: 'relative' }} />}
        <Collapse in={expanded || showMore} className={classes.form}>
          <NameInput
            value={name}
            onChange={onNameChanged}
          />
          <DescriptionInput
            value={description || ''}
            onChange={onDescriptionChanged}
          />
          <NameInput
            value={linkText}
            onChange={onLinkTextChanged}
            required={false}
            label="Link Text"
          />
        </Collapse>

      </div>
      <CardActions>
        <div>
          <Portal container={actionArea} disablePortal={!actionArea}>
            {(!showMore && !expanded)
              && (
                <Button
                  size="large"
                  color="primary"
                  onClick={() => { setShowMore(true); }}
                  id="website-form-more-button"
                >
                  More
                </Button>
              )}
          </Portal>
        </div>
      </CardActions>
    </Card>
  );
}

BasicForm.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    linkText: PropTypes.string,
    url: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
  }),
  onChange: PropTypes.func.isRequired,
  isValidCallback: PropTypes.func,
  actionArea: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
};

BasicForm.defaultProps = {
  value: { name: '', description: '', url: '' },
  actionArea: undefined,
  isValidCallback: undefined,
};

export default withStyles(styles)(BasicForm);
