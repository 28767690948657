import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'packages/use-navigation/navigation-context';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import QuantitativeListView from '../../../view-widgets/quantitative-view/list';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: 8,
  },

  link: {
    marginLeft: 8,
    marginRight: 16,
    fontSize: 16,
    color: '#808080',
    textDecoration: 'none',
    marginBottom: 8,
  },

  linkInner: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};
function CustomPropView({
  values, classes, edit, editURL,
}) {
  return (
    <div className={classes.wrapper}>
      <QuantitativeListView values={values} />
      {
      edit
      && (
      <Link to={editURL} href={editURL} className={classes.link}>
        {
          values && values.length
            ? (
              <IconButton size="large">
                <EditIcon />
              </IconButton>
            )
            : (
              <span className={classes.linkInner}>
                <AddIcon />

                Add properties
              </span>
            )
        }
      </Link>
      )
    }
    </div>
  );
}
CustomPropView.propTypes = {
  classes: PropTypes.shape({ }).isRequired,
  edit: PropTypes.bool,
  editURL: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.node,
    unit: PropTypes.node,
    label: PropTypes.node,
  })),
};
CustomPropView.defaultProps = {
  edit: false,
  editURL: '',
  values: [],
};
export default withStyles(styles)(CustomPropView);
