import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

function TimePicker({ label, value, onChange }) {
  return (
    <TextField
      variant="standard"
      label={label}
      type="time"
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

TimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TimePicker.defaultProps = {
  label: undefined,
  value: '',
};

export default TimePicker;
