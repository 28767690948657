import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

function WeekDayPicker({
  classes: { container },
  exclusions,
  value,
  onChange,
}) {
  const options = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].filter(option => !exclusions.includes(option));
  return (
    <div className={container}>
      {
            options.map((day) => {
              const contained = value.filter(y => y === day).length;
              if (contained) {
                return (
                  <Chip
                    label={day}
                    key={day}
                    color="primary"
                    onClick={() => {
                      const newValueProp = value.filter(z => z !== day);
                      onChange([...newValueProp]);
                    }}
                  />
                );
              }
              return (
                <Chip
                  label={day}
                  key={day}
                  onClick={() => {
                    onChange([...value, day]);
                  }}
                />
              );
            })
        }
    </div>
  );
}

WeekDayPicker.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  exclusions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

WeekDayPicker.defaultProps = {
  value: [],
  exclusions: [],
};

export default withStyles(styles)(WeekDayPicker);
