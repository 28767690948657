import ContactPoint from './ContactPoint';
import Event from './Event';
import OpeningHours from './OpeningHours';
import Offer from './Offer';
import Organization from './Organization';
import Place from './Place';
import Product from './Product';
import Thing from './Thing';
import User from './User';
import Website from './Website';

export default {
  ContactPoint,
  Event,
  OpeningHours,
  Offer,
  Organization,
  Place,
  Product,
  Thing,
  User,
  Website,
  Link: Website,
};
