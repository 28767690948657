import getNextDaily from './daily';
import getNextWeekly from './weekly';
import getNextMonthly from './monthly';
import getNextYearly from './yearly';

export default (event) => {
  const isPending = Math.round(Date.now() / 1000) <= event.startDate;
  if (!event.recurrence || isPending) return event.startDate;
  const { frequency } = event.recurrence.recurrenceRule;
  switch (frequency) {
    case 'DAYS':
      return (getNextDaily(event));

    case 'WEEKS':
      return (getNextWeekly(event));

    case 'MONTHS':
      return (getNextMonthly(event));

    default:
      return (getNextYearly(event));
  }
};
