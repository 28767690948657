import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@mui/styles';
import ListCardBase from '../../../../view-widgets/list-card-item/base';
import nextEvent from '../../utils/next-event';
import styles from '../../../styles/views/list-card-item';

function ListCard({
  applet, classes, ...otherProps
}) {
  const {
    name, image,
  } = applet;
  return (
    <ListCardBase
      detail={moment.unix(nextEvent(applet)).calendar(null, { sameElse: 'dddd, MMMM Do YYYY, h:mm a' })}
      title={name}
      imageURL={image && image.contentURL}
      classes={classes}
      {...otherProps}
    />
  );
}

ListCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.number.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ListCard);
