import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDbContext } from 'App/context/db';
import REDUX_ACTIONS from '../redux/constants';
import errorApplets from '../redux/local-error-applets';

const useApplet = (id) => {
  const localApplet = useSelector(({ applets }) => applets[id]);
  const dispatch = useDispatch();
  const db = useDbContext();

  useEffect(() => {
    db.getApplet(id)
      .then((applet) => {
        if (
          !localApplet || applet._meta.updated !== localApplet._meta.updated
        ) {
          dispatch({
            id,
            applet,
            type: REDUX_ACTIONS.SET_APPLET,
          });

          dispatch({
            id,
            applet,
            type: REDUX_ACTIONS.SET_REFERENCE,
          });
        }
      })
      .catch((e) => {
        const isApermissionError = e.code === 'permission-denied';
        const isAnErrorApplet = !!errorApplets[id];
        if (isApermissionError && !isAnErrorApplet) {
          dispatch({
            id,
            type: REDUX_ACTIONS.REMOVE_APPLET,
          });
        }
      });
  }, [db, dispatch, id, localApplet]);

  return localApplet;
};

export default useApplet;
