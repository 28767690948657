const imagePropertyConfig = {
  name: 'Image',
  edit: {
    form: 'image-input',
    label: 'Change Image',
    handler: value => value,
    onDelete: (deleteField) => deleteField(),
  },
};
export default imagePropertyConfig;
