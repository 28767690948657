import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import CurrencySelect from '../currency-select';

const styles = {
  display: 'flex',
  width: '100%',
  alignItems: 'baseline',
};

function Component({ value: { price, priceCurrency }, onChange }) {
  return (
    <div style={styles}>
      <TextField
        variant="standard"
        type="number"
        label="Price"
        onChange={(e) => { onChange({ price: Number(e.target.value), priceCurrency }); }}
        value={price}
        fullWidth
      />
      <CurrencySelect
        onChange={(currency) => { onChange({ priceCurrency: currency, price }); }}
        value={priceCurrency}
      />
    </div>
  );
}

Component.propTypes = {
  value: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priceCurrency: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

Component.defaultProps = {
  value: {
    price: '',
    priceCurrency: undefined,
  },
};
export default Component;
