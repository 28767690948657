import * as properties from './properties';
import * as thingProperties from '../Thing/properties';
import * as views from './views';
import Avatar from './avatar';
import BasicForm from './forms/basic';

const {
  Card, ListCardItem, Accordion, ListItem,
} = views;

const ContactPointExtension = {
  views: {
    card: () => Card,
    listCardItem: () => ListCardItem,
    listItem: () => ListItem,
    expansionPanel: () => Accordion,
  },
  forms: {
    basic: () => BasicForm,
  },
  avatar: () => Avatar,
  properties: { ...thingProperties, ...properties },
  meta: {
    name: 'Anything',
  },
};
export default ContactPointExtension;
