import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import TimePicker from '../time-picker';
import withContainer from './container';

const styles = {
  label: {
    marginRight: 8,
  },
  timePickerContainer: {
    display: 'inline',
    marginLeft: 8,
  },
};

function TimeRange({
  value, onChange, label, classes,
}) {
  return (
    <div>
      <span className={classes.label}>{label}</span>
      <div
        className={classes.timePickerContainer}
      >
        <TimePicker
          label="From"
          value={value && value.from}
          onChange={(newTime) => {
            const newValue = value ? { ...value } : {};
            newValue.from = newTime;
            onChange(newValue);
          }}
        />
      </div>
      <div
        className={classes.timePickerContainer}
      >
        <TimePicker
          label="To"
          value={value && value.to}
          onChange={(newTime) => {
            const newValue = value ? { ...value } : {};
            newValue.to = newTime;
            onChange(newValue);
          }}
        />
      </div>
    </div>
  );
}

TimeRange.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

TimeRange.defaultProps = {
  label: undefined,
  value: undefined,
};

export default withContainer(withStyles(styles)(TimeRange));
