import React from 'react';
import PropTypes from 'prop-types';
import PhoneIcon from '@mui/icons-material/Phone';
import { Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import defaultStyles from '../../../styles/views/expansion-panel';
import BaseExpPanel from '../../../../view-widgets/expansion-panel/base';
import getHostname from '../utils/get-hostname';

function getAvatarFallBack(applet) {
  if (applet.url && applet.url.startsWith('tel:')) {
    return <PhoneIcon />;
  }
  return applet.name && applet.name[0].toUpperCase();
}
function PanelListItem({
  applet,
  ...otherProps
}) {
  const {
    image, logo, name, url, description, actions,
  } = applet;

  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && getAvatarFallBack(applet)}
    </Avatar>
  );

  return (
    <BaseExpPanel
      avatar={avatar}
      primary={name}
      caption={getHostname(url)}
      secondary={description}
      actions={actions}
      {...otherProps}
    />
  );
}

PanelListItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

PanelListItem.defaultProps = {
  applet: {
    url: '',
    description: '',
  },
};

export default withStyles(defaultStyles)(PanelListItem);
