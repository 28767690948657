import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import BrokenImage from '@mui/icons-material/BrokenImage';
import { Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Image from 'components/image';
import defaultStyles from '../styles';

function Base({
  imageURL, primary, secondary, url, viewProps, classes,
}) {
  let PaperComp = 'div';
  let target = '';
  let rel = '';
  if (url && url.startsWith('/')) {
    PaperComp = Link;
  } else if (url) {
    PaperComp = 'a';
    if (!url.startsWith('tel:')) {
      target = '_blank';
      rel = 'noopener';
    }
  }
  return (
    <Paper
      elevation={viewProps.elevation}
      className={classes.root}
      square
    >
      <PaperComp
        href={url}
        rel={rel}
        to={url}
        target={target}
        className={classes.imageContainer}
      >
        {imageURL
          ? <Image src={imageURL} alt={primary} className={classes.image} />
          : <BrokenImage className={classes.imageAlt} />}
      </PaperComp>
      <div className={classes.content}>
        <Typography
          variant="subtitle1"
          color="inherit"
          className={classes.singleLine}
        >
          {primary}
        </Typography>
        {
            secondary
            && (
            <Typography
              variant="caption"
              color="inherit"
              className={classes.singleLine}
            >
              {secondary}
            </Typography>
            )
        }
      </div>
    </Paper>
  );
}

Base.propTypes = {
  imageURL: PropTypes.string,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.node,
  url: PropTypes.string,
  classes: PropTypes.shape({}),
  viewProps: PropTypes.shape({
    elevation: PropTypes.number,
  }),
};

Base.defaultProps = {
  imageURL: undefined,
  secondary: undefined,
  url: undefined,
  classes: {},
  viewProps: {},
};

export default withStyles(defaultStyles)(Base);
