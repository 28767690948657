import compose from 'recompose/compose';
import setPropTypes from 'recompose/setPropTypes';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import PropTypes from 'prop-types';

const onValueChange = ({ setValue, onChange }) => (value) => {
  setValue(value);
  if (value.from && value.to) {
    onChange(value);
  }
};

export default compose(
  setPropTypes({
    onChange: PropTypes.func.isRequired,
  }),
  withState('value', 'setValue', ({ value }) => value),
  withHandlers({
    onChange: onValueChange,
  }),
);
