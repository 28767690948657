import findIndex from 'lodash/findIndex';
import placeNextOpeningHours from './next-open-hours-business-local';
import toLocalTime from '../to-local-time';
import in12Hours from '../to12HourInterpreter';

const jsDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const jsDaysShort = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const timeArray = timeString24 => timeString24.split(':').map(v => Number(v));

const timezoneOffsetBreaker = (minutesOffset) => {
  const offsetHours = minutesOffset > 0
    ? Math.floor(minutesOffset / 60)
    : Math.ceil(minutesOffset / 60);
  const offsetMinutes = minutesOffset % 60;
  return [offsetHours, offsetMinutes];
};

const timezoneOffsetUser = (hours, minutes, offsetHours, offsetMinutes, toUTC = true) => {
  let newHours = toUTC ? hours + offsetHours : hours - offsetHours;
  let newMinutes = toUTC ? minutes + offsetMinutes : minutes - offsetMinutes;
  newHours += Math.floor(newMinutes / 60);
  newMinutes %= 60;
  return [newHours, newMinutes];
};

const weekDayIndexRectifier = (weekDayIndex) => {
  let validIndex = weekDayIndex > 6 ? 7 - weekDayIndex : weekDayIndex;
  validIndex = validIndex < 0 ? 7 + validIndex : validIndex;
  return validIndex;
};

const hoursMinutesStringInterpreter = (hours, minutes) => {
  let hoursStr = hours > 0 ? `${hours} hours` : '';
  hoursStr = hours === 1 ? `${hours} hour` : hoursStr;
  let minutesStr = minutes > 0 ? `${minutes} minutes` : '';
  minutesStr = minutes === 1 ? `${minutes} minute` : minutesStr;
  let string = '';
  string += hoursStr;
  string += hoursStr && minutesStr ? ' and ' : '';
  string += minutesStr;

  return string;
};

const currentDayAndTime = (placeTimezoneOffset) => {
  const nowUTCTimestamp = Date.now();
  const placeLocalTimeStamp = nowUTCTimestamp - (placeTimezoneOffset * 60 * 1000);
  const placeLocalCurrentDateTime = new Date(placeLocalTimeStamp);
  return [
    placeLocalCurrentDateTime.getUTCDay(),
    placeLocalCurrentDateTime.getUTCHours(),
    placeLocalCurrentDateTime.getUTCMinutes(),
  ];
};

const timeDifference = (timeArray1, timeArray2, dayAhead = false) => {
  // Assumption: timeArray2 is ahead of timeArray1
  const [hours1, minutes1] = timeArray1;
  const [hours2, minutes2] = timeArray2;
  let hoursDifference;
  // If hours2 > hours1, we are in the same day, otherwise hours2 is in a day ahead
  if (dayAhead) {
    hoursDifference = (hours2 + 24) - hours1;
  } else {
    hoursDifference = hours2 - hours1;
    // hoursDifference = hours2 >= hours1 ? hours2 - hours1 : (hours2 + 24) - hours1;
  }

  let minutesDifference = minutes2 - minutes1;
  if (minutesDifference < 0) {
    minutesDifference += 60;
    hoursDifference -= 1;
  }
  return [hoursDifference, minutesDifference];
};

const generateDayIncrement = (
  nextOpeningTimePlaceLocalArray,
  placeTimezoneOffset,
  viewerTmzOffset,
) => {
  const brokenPlaceLocalTime = nextOpeningTimePlaceLocalArray;
  const brokenPlaceTimezoneOffset = timezoneOffsetBreaker(placeTimezoneOffset);
  const brokenUTCTime = timezoneOffsetUser(...brokenPlaceLocalTime, ...brokenPlaceTimezoneOffset);
  const brokenViewerTmzOffset = timezoneOffsetBreaker(viewerTmzOffset);
  const viewerLocalHours = timezoneOffsetUser(...brokenUTCTime, ...brokenViewerTmzOffset, false)[0];

  if (viewerLocalHours > 23) {
    return 1;
  } if (viewerLocalHours < 0) {
    return -1;
  }
  return 0;
};

const genericNextOpeningStr = (
  viewerTmzOffset,
  placeTimezoneOffset,
  nextOpeningTimePlaceLocal,
  nextOpeningTimePlaceLocalArray,
  nextOpeningDayPlaceLocalIndex,
  viewerLocalDayIndex,
  openingTodayLocal,
) => {
  let nextOpeningStr = 'Will open ';
  let nextOpeningHourStr = 'at ';
  let nextOpeningDayStr = '';
  if (
    viewerTmzOffset === placeTimezoneOffset
    && openingTodayLocal
  ) {
    nextOpeningHourStr += `${in12Hours(nextOpeningTimePlaceLocal)}`;
  } else {
    const dayIndexIncrement = generateDayIncrement(
      nextOpeningTimePlaceLocalArray,
      placeTimezoneOffset,
      viewerTmzOffset,
    );
    const nextOpeningHourViewerLocal = toLocalTime(nextOpeningTimePlaceLocal, placeTimezoneOffset);
    const nextOpeningHourStrArr = timeArray(nextOpeningHourViewerLocal);

    nextOpeningHourStr += `${in12Hours(nextOpeningHourViewerLocal)}`;

    let nextOpeningDayViewerIndex = nextOpeningDayPlaceLocalIndex + dayIndexIncrement;
    nextOpeningDayViewerIndex = weekDayIndexRectifier(nextOpeningDayViewerIndex);

    const nowUTCTime = new Date(Date.now());
    const nowUTCTimeArray = [
      nowUTCTime.getUTCHours(),
      nowUTCTime.getUTCMinutes(),
    ];

    const viewerCurrentHoursAndMinutes = timezoneOffsetUser(
      ...nowUTCTimeArray,
      ...timezoneOffsetBreaker(viewerTmzOffset),
      false,
    );

    if (nextOpeningDayViewerIndex === viewerLocalDayIndex) {
      const timeDiff = timeDifference(viewerCurrentHoursAndMinutes, nextOpeningHourStrArr);
      if (timeDiff[0] < 5) {
        nextOpeningStr = `Opening in ${hoursMinutesStringInterpreter(...timeDiff)}`;
        nextOpeningHourStr = '';
      }
    } else if (
      (nextOpeningDayViewerIndex - viewerLocalDayIndex) === 1
      || (nextOpeningDayViewerIndex - viewerLocalDayIndex) === -6 // Saturday - Sunday
    ) {
      const timeDiff = timeDifference(viewerCurrentHoursAndMinutes, nextOpeningHourStrArr, true);
      if (timeDiff[0] < 5) {
        nextOpeningStr = `Opening in ${hoursMinutesStringInterpreter(...timeDiff)}`;
        nextOpeningHourStr = '';
      } else {
        nextOpeningStr += 'tomorrow ';
      }
    } else {
      nextOpeningDayStr += `on ${jsDays[nextOpeningDayViewerIndex]} `;
    }
  }
  nextOpeningStr += nextOpeningDayStr;
  nextOpeningStr += nextOpeningHourStr;
  return nextOpeningStr;
};

const placeNextOpeningLocal = (openingHours, openStatus) => {
  if (!openingHours || !openingHours.length) return null;
  if (openStatus === 'Open now') return null;
  const { timezoneOffset: placeTimezoneOffset } = openingHours[0];
  const [nextOpeningDayPlaceLocal, nextOpeningTimePlaceLocal] = placeNextOpeningHours(openingHours);
  const nextOpeningDayPlaceLocalIndex = findIndex(
    jsDaysShort,
    day => day === nextOpeningDayPlaceLocal,
  );

  const viewerTmzOffset = new Date().getTimezoneOffset();

  const [
    placeLocalDayIndex,
    placeLocalHours,
    placeLocalMinutes,
  ] = currentDayAndTime(placeTimezoneOffset);

  const viewerLocalDayIndex = currentDayAndTime(viewerTmzOffset)[0];

  const openingTodayLocal = placeLocalDayIndex === nextOpeningDayPlaceLocalIndex;

  const nextOpeningTimePlaceLocalArray = timeArray(nextOpeningTimePlaceLocal);

  let nextOpeningStr = '';
  if (
    nextOpeningDayPlaceLocalIndex === placeLocalDayIndex // Possible today cases
  ) {
    const hoursAndMinutesTillOpening = timeDifference(
      [placeLocalHours, placeLocalMinutes],
      nextOpeningTimePlaceLocalArray,
    );
    if (hoursAndMinutesTillOpening[0] < 5) {
      nextOpeningStr += 'Opening in ';
      nextOpeningStr += hoursMinutesStringInterpreter(...hoursAndMinutesTillOpening);
    }
  }

  if (!nextOpeningStr) {
    nextOpeningStr = genericNextOpeningStr(
      viewerTmzOffset,
      placeTimezoneOffset,
      nextOpeningTimePlaceLocal,
      nextOpeningTimePlaceLocalArray,
      nextOpeningDayPlaceLocalIndex,
      viewerLocalDayIndex,
      openingTodayLocal,
    );
  }

  return nextOpeningStr;
};

export default placeNextOpeningLocal;
