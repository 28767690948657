const getHostname = (url: string): string => {
  try {
    if (url.startsWith('tel:+')) {
      return getHostname(url.replace('tel:', 'tel://'));
    }
    return new URL(url).hostname;
  } catch (error) {
    return url;
  }
};
export default getHostname;
