import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@mui/styles';
import nextEvent from '../../utils/next-event';
import defaultStyles from '../../../styles/views/mini-card';
import MiniCardBase from '../../../../view-widgets/mini-card/base';

function MiniCard({
  applet,
  classes,
  ...otherProps
}) {
  const { name, image, logo } = applet;
  const next = moment.unix(nextEvent(applet)).calendar(null, { sameElse: 'dddd, MMMM Do YYYY, h:mm a' });
  return (
    <MiniCardBase
      imageURL={image && image.contentURL}
      logoURL={logo && logo.contentURL}
      subheader={next}
      title={name}
      classes={classes}
      {...otherProps}
    />
  );
}

MiniCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(defaultStyles)(MiniCard);
