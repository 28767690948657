import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withPropsOnChange from 'recompose/withPropsOnChange';
import placeOpenOrClosed from '../place-open-or-closed';
import placeNextOpening from '../place-next-opening';

const generatePlaceNextOpening = (openHours, status) => placeNextOpening(openHours, status);

export default compose(
  withState('openStatus', 'setOpenStatus', ({ openingHours }) => placeOpenOrClosed(openingHours)),
  withState('nextOpening', 'setNextOpening', ({ openingHours, openStatus }) => generatePlaceNextOpening(openingHours, openStatus)),
  lifecycle({
    componentDidMount() {
      const {
        setOpenStatus, setNextOpening, openingHours,
      } = this.props;
      this.intervalId = setInterval(() => {
        const newOpenStatus = placeOpenOrClosed(openingHours);
        setOpenStatus(newOpenStatus);
        setNextOpening(generatePlaceNextOpening(openingHours, newOpenStatus));
      }, 1000);
    },
    componentWillUnmount() {
      clearInterval(this.intervalId);
    },
  }),
  withPropsOnChange(
    ['openStatus'],
    ({ openingHours, openStatus, setNextOpening }) => {
      const newNextOpening = generatePlaceNextOpening(openingHours, openStatus);
      setNextOpening(newNextOpening);
    },
  ),
);
