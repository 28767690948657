import { Button } from '@mui/material';
import React from 'react';

interface IProps {
  text: string;
  children: any;
  disabled?: boolean;
  [propName: string]: unknown;
}

function FormSubmitButton({
  text, children, disabled, ...props
}: IProps) {
  return (
    <Button disabled={disabled} variant="contained" size="large" color="primary" {...props}>
      {children || text}
    </Button>
  );
}
FormSubmitButton.defaultProps = {
  disabled: false,
};

export default FormSubmitButton;
