import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

function Component(props) {
  const { maxLength } = props;
  const style = {
    div: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      margin: '0 auto',
    },

    textField: {
      color: '#00BCD4',
    },
  };
  return (
    <div style={style.div}>
      <TextField
        variant="standard"
        style={style.textField}
        inputProps={{ maxLength }}
        {...props}
        fullWidth
      />
    </div>
  );
}

Component.propTypes = {
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
};
Component.defaultProps = {
  value: undefined,
  maxLength: 60,
  required: false,
  label: 'Brand',
};

export default Component;
