import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import Camera from 'react-icons/lib/fa/camera';
import PhotoAdd from 'react-icons/lib/md/add-a-photo';
import Image from 'components/image';

import { withStyles } from '@mui/styles';

const styles = {
  bannerWrapper: {
    minHeight: '25vh',
    // overflow: 'hidden',
    // transition: 'max-height 0.4s ease-out',
    // '&:hover': {
    //   maxHeight: '100vh',
    //   transition: 'max-height 0.4s ease-in',
    // },
  },
  bannerImage: {
    width: '100%',
    height: 'auto',
  },
  imageEditLink: {
    width: '100%',
    height: '25vh',
    color: '#fff',
    backgroundColor: '#000000aa',
    zIndex: 10,
    position: 'relative',
    marginTop: '-25vh',
    fontSize: 50,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 5px',
  },
  imageAddIcon: {
    fontSize: 80,
  },
};

styles.imageAddLink = {
  ...styles.imageEditLink,
  marginTop: undefined,
  top: undefined,
  float: undefined,
  marginBottom: 10,
  fontSize: 40,
  flexDirection: 'column',
};

function BannerView({
  image, edit, editURL, classes,
}) {
  return (
    <>
      {
        image
        && (
        <div className={classes.bannerWrapper}>
          <Image className={classes.bannerImage} src={image.contentURL} alt="" />
        </div>
        )
    }
      {
        edit
          && (
          <Link
            to={editURL}
            href={editURL}
            className={image ? classes.imageEditLink : classes.imageAddLink}
          >
            {
              image
                ? (
                  <>
                    <Camera />
                    <span>&nbsp;Change</span>
                  </>
                )
                : (
                  <PhotoAdd className={classes.imageAddIcon} />
                )
            }
          </Link>
          )
      }
    </>
  );
}

BannerView.propTypes = {
  image: PropTypes.shape({
    contentURL: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  edit: PropTypes.bool,
  editURL: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
};

BannerView.defaultProps = {
  image: null,
  edit: false,
  editURL: null,
};

export default withStyles(styles)(BannerView);
