import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { useNavigationContext } from 'packages/use-navigation/navigation-context';
import { makeStyles } from '@mui/styles';

const bdProps = {
  style: {
    // backgroundColor: 'transparent',
  },
};
const inDialog = (C) => {
  const useStyles = makeStyles({
    paper: {
      width: '80%',
      maxHeight: '80%',
      maxWidth: 500,
    },
  });
  function Dialogged({
    onCloseRoute, BackdropProps, onClose, ...props
  }) {
    const classes = useStyles();
    const { history } = useNavigationContext();
    return (
      <Dialog
        open
        onClose={() => {
          if (onClose) {
            onClose();
          } else {
            history.replace(onCloseRoute);
          }
        }}
        BackdropProps={{ ...bdProps, ...BackdropProps }}
        classes={{ paper: classes.paper }}
      >
        <C
          {...props}
          onClose={onClose}
          onCloseRoute={onCloseRoute}
          history={history}
          styles={{ height: '100%' }}
        />
      </Dialog>
    );
  }
  Dialogged.propTypes = {
    onCloseRoute: PropTypes.string.isRequired,
    BackdropProps: PropTypes.shape({}),
    onClose: PropTypes.func,
  };

  Dialogged.defaultProps = {
    BackdropProps: {},
    onClose: undefined,
  };
  return (Dialogged);
};

export default inDialog;
