import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Fab, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import SingleInput from './single-input';
import withContainer from './container';
import dayExclusions from './utils/day-exclusions';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deleteIconDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
  iconsInputDiv: {
    border: '1px solid #d3d3d3',
    width: 'fit-content',
    padding: '8px 16px',
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

function OpenHoursForm({
  value, emptyForm, onChange, onDelete, addNewForm, classes,
}) {
  return (
    <div className={classes.root}>
      {
        value.map((daysAndOpenHours, index) => {
          const reactKey = index;
          return (
            <div
              key={reactKey}
              className={classes.iconsInputDiv}
            >
              <div className={classes.deleteIconDiv}>
                <IconButton onClick={() => { onDelete(index); }} size="large">
                  <DeleteIcon />
                </IconButton>
              </div>
              <SingleInput
                value={daysAndOpenHours}
                dayExclusions={
                    dayExclusions(value).filter(day => !daysAndOpenHours.days.includes(day))
                  }
                onChange={newDaysAndOpenHours => onChange(newDaysAndOpenHours, index)}
              />
            </div>
          );
        })
    }
      {
      emptyForm && dayExclusions(value).length < 7
        && (
        <div className={classes.iconsInputDiv}>
          <div className={classes.deleteIconDiv}>
            <IconButton onClick={() => { onDelete(); }} size="large">
              <DeleteIcon />
            </IconButton>
          </div>
          <SingleInput
            onChange={onChange}
            dayExclusions={dayExclusions(value)}
          />
        </div>
        )
    }
      <Fab
        onClick={addNewForm}
        disabled={emptyForm || dayExclusions(value).length === 7}
        color="primary"
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

OpenHoursForm.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.string),
    openHours: PropTypes.arrayOf(PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    })),
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  addNewForm: PropTypes.func.isRequired,
  emptyForm: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(withContainer(OpenHoursForm));
