const Styles = {
  root: {
    whiteSpace: 'pre-wrap',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 400,
    textDecoration: 'none',
  },

  summary: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 16px',
    fontSize: '1rem',
    fontWeight: 400,
    overflow: 'hidden',
  },

  summaryText: {
    textDecoration: 'none',
  },

  summaryRoot: {
    '& >*': {
      alignItems: 'center',
      minWidth: 0,
    },
  },

  caption: {
  },

  avatar: {
    width: '64px',
    height: '64px',
    marginRight: '.5em',
  },

  divider: {
    width: '98%',
    margin: '0 auto',
  },

  actionLink: { textDecoration: 'none' },

  actionButton: { textTransform: 'none' },

  price: {
    // fontStyle: 'italic',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 16px 16px 0',
  },
  openingHoursContainer: {
    width: '100%',
    marginBottom: '10px',
    display: 'grid',
    gridRowGap: '20px',
  },
};

export default Styles;
