import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import PriceView from '../../../../view-widgets/price';
import ListItemBaseView from '../../../../view-widgets/list-item/base';
import DefaultAvatar from '../../avatar';

function ListItemView({
  applet: {
    name, image, logo, priceSpecification,
  },
  ...otherProps
}) {
  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && <DefaultAvatar />}
    </Avatar>
  );
  return (
    <ListItemBaseView
      primary={name}
      secondary={
        priceSpecification
        && priceSpecification.price
        && <PriceView value={priceSpecification} />
      }
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    priceSpecification: PropTypes.shape({
      price: PropTypes.number,
      priceCurrency: PropTypes.string,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
};

export default ListItemView;
