import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import PriceView from '../../../../view-widgets/price';
import styles from '../../../styles/views/list-card-item';
import ListCardBase from '../../../../view-widgets/list-card-item/base';

function ListCard({
  applet: {
    name, priceSpecification, image,
  },
  classes,
  ...otherProps
}) {
  const price = priceSpecification
                && priceSpecification.price
                && <PriceView value={priceSpecification} />;
  return (
    <ListCardBase
      title={name}
      detail={price}
      imageURL={image && image.contentURL}
      classes={classes}
      {...otherProps}
    />
  );
}

ListCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    priceSpecification: PropTypes.shape({
      price: PropTypes.number,
      priceCurrency: PropTypes.string,
    }),
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ListCard);
