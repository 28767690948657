import * as thingProperties from '../Thing/properties';
import * as properties from './properties';
import Card from './views/card';
import MiniCard from './views/mini-card';
import ListCardItem from './views/list-card-item';
import ListItem from './views/list-item';
import Accordion from './views/expansion-panel';
import ImageViewer from './views/image-viewer';
import Main from './views/main';
import BasicForm from './forms/basic';
import Avatar from './avatar';

const WebsiteExtension = {
  views: {
    main: () => Main,
    card: () => Card,
    miniCard: () => MiniCard,
    listCardItem: () => ListCardItem,
    listItem: () => ListItem,
    expansionPanel: () => Accordion,
    imageViewer: () => ImageViewer,
  },
  forms: {
    basic: () => BasicForm,
  },
  avatar: () => Avatar,
  properties: { ...thingProperties, ...properties },
  meta: {
    name: 'Website',
  },
};
export default WebsiteExtension;
