import React from 'react';
import PropTypes from 'prop-types';
import ContactPhone from '@mui/icons-material/ContactPhone';
import Mail from '@mui/icons-material/Mail';
import { Avatar } from '@mui/material';
import DefaultAvatar from '../../avatar';
import ListItemBaseView from '../../../../view-widgets/list-item/base';

function ListItemView({
  applet: {
    name, description, email, telephone, image, logo,
  },
  ...otherProps
}) {
  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {(!avatarSrc && telephone) && <ContactPhone />}
      {(!avatarSrc && !telephone && email) && <Mail />}
      {(!avatarSrc && !telephone && !email) && <DefaultAvatar />}
    </Avatar>
  );
  return (
    <ListItemBaseView
      primary={telephone || email || name}
      secondary={(telephone || email) ? name : description}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    telephone: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};

ListItemView.defaultProps = {
  applet: {
    telephone: undefined,
    email: undefined,
    name: undefined,
    description: undefined,
  },
};
export default ListItemView;
