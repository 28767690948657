export default ({ from, to, timezoneOffset: tmzOffset }) => {
  if (from === to) return true;

  const nowUTC = new Date(Date.now());
  const nowUTCHours = nowUTC.getUTCHours();
  const nowUTCMinutes = nowUTC.getUTCMinutes();
  const tmzOffsetHours = tmzOffset > 0 ? Math.floor(tmzOffset / 60) : Math.ceil(tmzOffset / 60);
  const tmzOffsetMinutes = tmzOffset % 60;

  let nowLocalHours = nowUTCHours - tmzOffsetHours;
  let nowLocalMinutes = nowUTCMinutes - tmzOffsetMinutes;
  nowLocalHours += Math.floor(nowLocalMinutes / 60);
  nowLocalMinutes %= 60;

  nowLocalHours = nowLocalHours < 0 ? (24 + nowLocalHours) : nowLocalHours;
  nowLocalHours = nowLocalHours >= 24 ? (nowLocalHours - 24) : nowLocalHours;
  nowLocalMinutes = nowLocalMinutes < 0 ? (60 + nowLocalMinutes) : nowLocalMinutes;
  nowLocalMinutes = `${nowLocalMinutes}`.length === 1 ? `0${nowLocalMinutes}` : nowLocalMinutes;

  const nowLocalTime = Number(`${nowLocalHours}${nowLocalMinutes}`);

  const min = Number(from.replace(':', ''));
  const max = Number(to.replace(':', ''));

  return (nowLocalTime >= min && nowLocalTime <= max)
          || (nowLocalTime >= max && nowLocalTime <= min);
};
