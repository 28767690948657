import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cells: {
    display: 'table-cell',
    padding: '4px 8px',
  },
  label: {
    justifySelf: 'right',
  },
  value: {
    paddingRight: 2,
  },
  unit: {
    paddingLeft: 2,
  },
});

function QuantitativeView({ value: { label, value, unit } }) {
  const classes = useStyles();
  return (
    <>
      <Typography align="center" className={`${classes.cells} ${classes.label}`} variant="body2">
        {label}
      </Typography>
      <span>
        <Typography align="center" className={`${classes.cells} ${classes.value}`} variant="subtitle2">{value}</Typography>
        <Typography align="center" className={`${classes.cells} ${classes.unit}`} variant="caption">{unit}</Typography>
      </span>
    </>
  );
}

QuantitativeView.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number]),
    unit: PropTypes.string,
  }).isRequired,
};

export default QuantitativeView;
