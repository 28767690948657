import getMetadata from './get-url-metadata';
import debounce from './debounce';
import describeLink from './describe-link';

function isValidWebUrl(urlString: string) {
  let url;
  try {
    url = new URL(urlString);
  } catch (error) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

function isValidUrl(str: string) {
  try {
    return !!new URL(str);
  } catch (error) {
    return false;
  }
}

const fetchMetadata = debounce(getMetadata, 2000);

async function parseURL(url: string) {
  if (isValidWebUrl(url)) {
    const metadata = await fetchMetadata(url);
    const result = { data: metadata } || Promise.resolve(null);

    return result;
  } if (isValidUrl(url)) {
    return describeLink(url);
  } return null;
}

export default parseURL;
