import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import nextEvent from '../../utils/next-event';
import ListItemBaseView from '../../../../view-widgets/list-item/base';
import DefaultAvatar from '../../avatar';

function ListItemView({ applet, ...otherProps }) {
  const { name, logo, image } = applet;
  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && <DefaultAvatar />}
    </Avatar>
  );
  return (
    <ListItemBaseView
      primary={name}
      secondary={
      moment.unix(nextEvent(applet))
        .calendar(null, { sameElse: 'dddd, MMMM Do YYYY, h:mm a' })
      }
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
};

export default ListItemView;
