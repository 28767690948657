import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/list-card-item';
import ListCardBase from '../../../../view-widgets/list-card-item/base';
import placeNextOpeningLocal from '../../utils/place-next-opening';
import { isOpen } from '../../utils/place-open-or-closed';

function ListCard({
  applet: {
    name, openingHours, image,
  },
  classes,
  ...otherProps
}) {
  return (
    <ListCardBase
      title={isOpen(openingHours) ? 'Open Now' : placeNextOpeningLocal(openingHours)}
      detail={name}
      imageURL={image && image.contentURL}
      classes={classes}
      {...otherProps}
    />
  );
}

ListCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    openingHours: PropTypes.arrayOf(PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.string),
      openingHours: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      })),
      timezoneOffset: PropTypes.number,
    })),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ListCard);
