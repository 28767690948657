import React from 'react';
import PropTypes from 'prop-types';
import Base from '../../../../view-widgets/image-view/base';
import withOpenStatus from '../../utils/with-place-open-status';

function ImageViewer({
  applet: {
    image,
    name,
    openingHours,
  },
  ...otherProps
}) {
  const OpenStatus = withOpenStatus(({ openStatus }) => openStatus);
  return (
    <Base
      imageURL={image && image.contentURL}
      primary={name}
      secondary={<OpenStatus openingHours={openingHours} />}
      {...otherProps}
    />
  );
}

ImageViewer.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    openingHours: PropTypes.arrayOf(PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.string),
      openingHours: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      })),
      timezoneOffset: PropTypes.number,
    })),
  }).isRequired,
};

export default ImageViewer;
