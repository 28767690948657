import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import BrandInput from '../../../../forms/brand-input';
import NameInput from '../../../../forms/name-input';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  imageInputDiv: {
    marginBottom: '2.5em',
  },
};

function BasicForm(props) {
  const {
    onChange, isValidCallback, value,
  } = props;
  useEffect(() => {
    if (isValidCallback) {
      if (value && value.name && value.name.trim()) {
        isValidCallback(true);
      } else {
        isValidCallback(false);
      }
    }
  });
  return (
    <Card elevation={0}>
      <div style={styles.container}>
        <NameInput
          required
          label="Product Name"
          value={(value && value.name) || ''}
          onChange={(e) => { onChange({ ...value, name: e.target.value }); }}
        />
        <BrandInput
          label="Brand"
          value={(value && value.brand) || ''}
          onChange={(e) => { onChange({ ...value, brand: e.target.value }); }}
        />
      </div>
    </Card>
  );
}

BasicForm.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    brand: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isValidCallback: PropTypes.func,
};

BasicForm.defaultProps = {
  value: {
    name: '',
    brand: '',
  },
  isValidCallback: undefined,
};

export default BasicForm;
