import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import NameInput from '../../../../forms/name-input';
import DescriptionInput from '../../../../forms/description-input';
import PriceForm from '../../../../forms/price-spec-input';

function Basic({
  value,
  onChange,
  isValidCallback,
}) {
  const { name, description, priceSpecification } = { name: '', description: '', ...value };
  if (isValidCallback) {
    if (value && value.name && value.name.trim()) {
      isValidCallback(true);
    } else {
      isValidCallback(false);
    }
  }
  function update(params) {
    onChange({ ...value, ...params });
  }
  return (
    <Card elevation={0}>
      <NameInput
        value={name}
        onChange={(e) => { update({ name: e.target.value }); }}
        required
      />
      <DescriptionInput
        value={description}
        onChange={(e) => { update({ description: e.target.value }); }}
      />
      <PriceForm
        value={priceSpecification}
        onChange={(val) => { update({ priceSpecification: val }); }}
      />
    </Card>
  );
}

Basic.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    priceSpecification: PropTypes.shape({
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      priceCurrency: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func.isRequired,
  isValidCallback: PropTypes.func,
};

Basic.defaultProps = {
  value: undefined,
  isValidCallback: undefined,
};

export default Basic;
