import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import setPropTypes from 'recompose/setPropTypes';
import PropTypes from 'prop-types';

const toggleNewOpenHoursForm = ({ showEmptyForm, newOpenHoursForm }) => () => {
  showEmptyForm(!newOpenHoursForm);
};

const onValueChange = ({ setValue, onChange }) => (value) => {
  setValue(value);
  if (value.days && value.days.length && value.openHours && value.openHours.length) {
    onChange({ ...value, timezoneOffset: new Date().getTimezoneOffset() });
  }
};

export default compose(
  setPropTypes({
    onChange: PropTypes.func.isRequired,
  }),
  withState('newOpenHoursForm', 'showEmptyForm', ({ value }) => (!(value && value.openHours && value.openHours.length))),
  withState('value', 'setValue', ({ value }) => ({ ...value, timezoneOffset: undefined })),
  withHandlers({
    toggleNewOpenHoursForm,
    onChange: onValueChange,
  }),
);
