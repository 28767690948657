import React from 'react';
import PropTypes from 'prop-types';
import getAppletPath from 'packages/extensions/utils/create-url';
import { withStyles } from '@mui/styles';
import { useAppConfig } from 'helper-hooks';
import styles from 'packages/extensions/styles/views/card';
import CardBase from 'packages/view-widgets/card/base';

function CardView({
  applet,
  classes,
  ...otherProps
}) {
  const {
    name, description, image, actions, logo, customProperties,
  } = applet;
  const { appName } = useAppConfig();
  const isLinkist = appName.toLowerCase() === 'linkist';
  return (
    <CardBase
      image={image}
      logo={logo}
      title={name}
      subheader={isLinkist && getAppletPath(applet)}
      description={description}
      actions={actions}
      classes={classes}
      customProperties={customProperties}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    logo: PropTypes.shape({

    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
  }).isRequired,
  classes: PropTypes.shape({ }).isRequired,
};

export default withStyles(styles)(CardView);
