import React from 'react';
import PropTypes from 'prop-types';
import Base from '../../../../view-widgets/image-view/base';

function ImageViewer({
  applet: {
    image,
    name,
    url,
  },
  ...otherProps
}) {
  return (
    <Base
      imageURL={image && image.contentURL}
      primary={name}
      secondary={url}
      {...otherProps}
    />
  );
}

ImageViewer.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
};

export default ImageViewer;
