import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import defaultStyles from '../../../styles/views/mini-card';
import MiniCardBase from '../../../../view-widgets/mini-card/base';

function MiniCard({
  applet: {
    name, description, brand, image,
  },
  classes,
  ...otherProps
}) {
  return (
    <MiniCardBase
      imageURL={image && image.contentURL}
      title={name}
      description={description}
      subheader={brand}
      classes={classes}
      {...otherProps}
    />
  );
}

MiniCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    brand: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(defaultStyles)(MiniCard);
