import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import useNavigation from 'packages/use-navigation';
import UserPrompt from './user-prompt';
import useActionButtonConfig from './use-action-button-config';

function ActionButton({
  action: { name, target },
}) {
  const { location } = useNavigation();
  const [userPrompted, setUserPrompted] = React.useState(false);

  let props = useActionButtonConfig(target);
  let promptButtonProps;

  const placeHolders = target.match(/<(.*?)>/g);

  if (placeHolders && placeHolders.length) {
    promptButtonProps = props;
    props = {
      onClick: () => {
        setUserPrompted(true);
      },
    };
  }

  return (
    userPrompted
      ? (
        <UserPrompt
          actionName={name}
          prompts={placeHolders}
          buttonProps={promptButtonProps}
          onCloseRoute={location.pathname}
          onClose={() => { setUserPrompted(false); }}
        />
      )
      : (
        <Button color="primary" variant="text" {...props}>
          {name}
        </Button>
      )
  );
}

ActionButton.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
};

export default ActionButton;
