const bothNegativeOrPositive = (var1, var2) => (var1 > 0 && var2 > 0) || (var1 < 0 && var2 < 0);
const conditionalFloor = (arg, floor = 60) => (
  arg > 0 ? Math.floor(arg / floor) : Math.ceil(arg / floor)
);

const isAtUTC = tmzOffset => tmzOffset === 0;

function timezoneDifference(tmzOffset1, tmzOffset2) {
  let tmzDifference;
  if (bothNegativeOrPositive(tmzOffset1, tmzOffset2)) {
    tmzDifference = tmzOffset1 - tmzOffset2;
  } else if (tmzOffset1 === 0 || tmzOffset2 === 0) {
    tmzDifference = tmzOffset1 || tmzOffset2;
  } else {
    tmzDifference = Math.abs(tmzOffset1 - tmzOffset2);
  }
  return tmzDifference;
}

const hoursMinutesRectifier = (hours, minutes) => {
  let newHours = hours >= 24 ? hours - 24 : hours;
  newHours = newHours < 0 ? newHours + 24 : newHours;
  const newMinutes = minutes < 0 ? minutes + 60 : minutes;
  return [newHours, newMinutes];
};

const hourMinuteString = (hours, minutes) => {
  const hoursStr = `${hours}`.length === 1 ? `0${hours}` : hours;
  const minutesStr = `${minutes}`.length === 1 ? `0${minutes}` : minutes;
  return `${hoursStr}:${minutesStr}`;
};

const hoursAddition = (
  setterTmzOffset,
  viewerTmzOffset,
  tmzDifferenceMinutes,
  localMinutes,
) => {
  let addition;
  if (tmzDifferenceMinutes > 0) {
    if (isAtUTC(setterTmzOffset) && tmzDifferenceMinutes > localMinutes) {
      addition = -1;
    } else {
      addition = Math.floor(localMinutes / 60);
    }
  } else if (isAtUTC(viewerTmzOffset) && Math.abs(tmzDifferenceMinutes) > localMinutes) {
    addition = -1;
  } else {
    addition = Math.floor(localMinutes / 60);
  }
  return addition;
};

export default function (time, setterTmzOffset) {
  const viewerTmzOffset = new Date().getTimezoneOffset();
  if (setterTmzOffset === viewerTmzOffset) return time;

  const tmzDifference = timezoneDifference(setterTmzOffset, viewerTmzOffset);
  const tmzDifferenceHours = conditionalFloor(tmzDifference);
  const tmzDifferenceMinutes = tmzDifference % 60;

  let [hours, minutes] = time.split(':').map(v => Number(v));

  const tmzOffsetsInSameDirection = bothNegativeOrPositive(setterTmzOffset, viewerTmzOffset);
  const addExtraTime = (
    tmzOffsetsInSameDirection
    || isAtUTC(viewerTmzOffset)
    || setterTmzOffset > 0
  );

  if (addExtraTime) {
    hours += tmzDifferenceHours;
    minutes += tmzDifferenceMinutes;
  } else {
    hours -= tmzDifferenceHours;
    minutes -= tmzDifferenceMinutes;
  }

  hours += hoursAddition(
    setterTmzOffset,
    viewerTmzOffset,
    tmzDifferenceMinutes,
    minutes,
  );
  minutes %= 60;

  return hourMinuteString(...hoursMinutesRectifier(hours, minutes));
}
