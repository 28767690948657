const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const pluralize = string => `${string}s`;

const includeWeekends = days => days.includes(5) && days.includes(6);

const areWeekDays = (days) => {
  const weekDays = [0, 1, 2, 3, 4];
  const nonWeekDays = days.filter(day => !weekDays.includes(day));
  return !nonWeekDays.length;
};

const arrayBreaker = (array) => {
  const following = [];
  for (let i = 0; i < array.length; i += 1) {
    if (following.length === 0) {
      following.push(array[i]);
    } else if (following.includes(array[i] - 1)) {
      following.push(array[i]);
    }
  }
  if (!following.length) return array;
  return [following, ...arrayBreaker(array.filter(entry => !following.includes(entry)))];
};

const oneDayInterpreter = (day, options) => (
  options && options.plural ? pluralize(allDays[day]) : allDays[day]);

const twoDaysIntepreter = (days, options) => {
  const { delimiter = ' and ', prefix = '', plural } = options;
  if (includeWeekends(days)) return `${prefix && ' and '}Weekend${plural ? 's' : ''}`;
  const daysToReturn = days.map(day => oneDayInterpreter(day, options));
  return prefix + daysToReturn.join(delimiter);
};

const threeDaysInterpreter = (days, options) => {
  if (arrayBreaker(days).length === 1) return `${oneDayInterpreter(days[0])} to ${oneDayInterpreter(days[2])}`;
  if (includeWeekends(days)) {
    const nonWeekend = days.filter(day => day < 5);
    return `${oneDayInterpreter(nonWeekend, options)} and Weekend${options && options.plural ? 's' : ''}`;
  }
  const firstDay = days.shift();
  return `${oneDayInterpreter(firstDay, options)}, ${twoDaysIntepreter(days, options)}`;
};

const fourDaysInterpreter = (days, options) => {
  const brokenArray = arrayBreaker(days);
  if (brokenArray.length === 1) {
    return `${oneDayInterpreter(days[0])} to ${oneDayInterpreter(days[3])}`;
  } if (brokenArray.length === 2) {
    switch (brokenArray[0].length) {
      case 1:
        return `${oneDayInterpreter(brokenArray[0][0], options)} and ${threeDaysInterpreter(brokenArray[1], options)}`;
      case 2:
        return `${twoDaysIntepreter(brokenArray[0], { ...options, delimiter: ', ' })}${twoDaysIntepreter(brokenArray[1], { ...options, prefix: ', ' })}`;
      default:
        return `${threeDaysInterpreter(brokenArray[0], options)} and ${oneDayInterpreter(brokenArray[1][0], options)}`;
    }
  } else if (brokenArray.length === 3) {
    if (brokenArray[0].length === 2) {
      return `${twoDaysIntepreter(brokenArray[0], { ...options, delimiter: ', ' })}${twoDaysIntepreter([brokenArray[1][0], brokenArray[2][0]], { ...options, prefix: ', ' })}`;
    } if (brokenArray[1].length === 2) {
      return `${oneDayInterpreter(brokenArray[0][0], options)}, ${twoDaysIntepreter(brokenArray[1], { ...options, delimiter: ', ' })} and ${oneDayInterpreter(brokenArray[2][0], options)}`;
    }
    return `${twoDaysIntepreter([brokenArray[0][0], brokenArray[1][0]], { ...options, delimiter: ', ' })}${twoDaysIntepreter(brokenArray[2], { ...options, prefix: ', ' })}`;
  }
  const stringArray = brokenArray.map(entry => (oneDayInterpreter(entry[0], options)));
  const lastString = stringArray.pop();
  return `${stringArray.join(', ')} and ${lastString}`;
};

const fiveDaysInterpreter = (days, options) => {
  if (areWeekDays(days)) return 'Weekdays';
  const brokenArray = arrayBreaker(days);
  if (brokenArray.length === 1) return `${allDays[days[0]]} to ${allDays[days[4]]}`;
  if (brokenArray.length === 2) {
    switch (brokenArray[0].length) {
      case 1:
        return `${oneDayInterpreter(brokenArray[0][0], options)} and ${fourDaysInterpreter(brokenArray[1], options)}`;
      case 2:
        return `${twoDaysIntepreter(brokenArray[0], { ...options, delimiter: ', ' })} and ${threeDaysInterpreter(brokenArray[1], options)}`;
      case 3:
        return `${threeDaysInterpreter(brokenArray[0], options)}${twoDaysIntepreter(brokenArray[1], { ...options, prefix: ', ' })}`;
      default:
        return `${fourDaysInterpreter(brokenArray[0], options)} and ${oneDayInterpreter(brokenArray[1][0], options)}`;
    }
  } else if
  (brokenArray[0].length === 3 || brokenArray[1].length === 3 || brokenArray[2].length === 3) {
    if (brokenArray[0].length === 3) {
      return `${threeDaysInterpreter(brokenArray[0], options)}, ${twoDaysIntepreter([brokenArray[1][0], brokenArray[2][0]], options)}`;
    } if (brokenArray[1].length === 3) {
      return `${oneDayInterpreter(brokenArray[0][0], options)}, ${threeDaysInterpreter(brokenArray[1], options)} and ${oneDayInterpreter(brokenArray[2][0], options)}`;
    }
    return `${twoDaysIntepreter([brokenArray[0][0], brokenArray[1][0]], { ...options, delimiter: ', ' })} and ${threeDaysInterpreter(brokenArray[2], options)}`;
  } else if (brokenArray[0].length === 1) {
    return `${oneDayInterpreter(brokenArray[0][0], options)}, ${twoDaysIntepreter(brokenArray[1], { ...options, delimiter: ', ' })}${twoDaysIntepreter(brokenArray[2], { ...options, prefix: ' and ' })}`;
  } else if (brokenArray[2].length === 1) {
    return `${twoDaysIntepreter(brokenArray[0], { ...options, delimiter: ', ' })}, ${twoDaysIntepreter(brokenArray[1], { ...options, delimiter: ', ' })} and ${oneDayInterpreter(brokenArray[2][0], options)}`;
  }
  return `${twoDaysIntepreter(brokenArray[0], { ...options, delimiter: ', ' })}, ${oneDayInterpreter(brokenArray[1][0], options)}${twoDaysIntepreter(brokenArray[2], { ...options, prefix: ', ' })}`;
};

const sixDaysInterpreter = (days, options) => {
  const brokenArray = arrayBreaker(days);
  if (brokenArray.length === 1) return `${oneDayInterpreter(days[0])} to ${oneDayInterpreter(days[5])}`;
  switch (brokenArray[0].length) {
    case 1:
      return `${oneDayInterpreter(brokenArray[0][0], options)} and ${fiveDaysInterpreter(brokenArray[1], options)}`;
    case 5:
      return `${fiveDaysInterpreter(brokenArray[0], options)} and ${oneDayInterpreter(brokenArray[1][0], options)}`;
    case 2:
      return `${twoDaysIntepreter(brokenArray[0], options)}, ${fourDaysInterpreter(brokenArray[1], options)}`;
    case 3:
      return `${threeDaysInterpreter(brokenArray[0], options)} and ${threeDaysInterpreter(brokenArray[1], options)}`;
    default:
      return `${fourDaysInterpreter(brokenArray[0], options)}${twoDaysIntepreter(brokenArray[1], { ...options, prefix: ' and ' })}`;
  }
};

const weekDaysEasy = (daysArray, interpretationOptions) => {
  const options = interpretationOptions || { plural: false };
  const days = daysArray.sort((a, b) => a - b);
  switch (days.length) {
    case 1:
      return oneDayInterpreter(days[0], options);
    case 2:
      return twoDaysIntepreter(days, options);
    case 3:
      return threeDaysInterpreter(days, options);
    case 4:
      return fourDaysInterpreter(days, options);
    case 5:
      return fiveDaysInterpreter(days, options);
    case 6:
      return sixDaysInterpreter(days, options);
    default:
      return 'Daily';
  }
};

export default weekDaysEasy;
