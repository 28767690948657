import * as thingProperties from '../Thing/properties';
import * as properties from './properties';
import Avatar from './avatar';
import BasicForm from './forms/basic';

const OrganizationExtension = {
  views: {},
  forms: {
    basic: () => BasicForm,
  },
  avatar: () => Avatar,
  properties: { ...thingProperties, ...properties },
  meta: { name: 'Organization' },
};
export default OrganizationExtension;
