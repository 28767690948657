import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import defaultStyles from '../../../styles/views/expansion-panel';
import BaseExpPanel from '../../../../view-widgets/expansion-panel/base';
import DefaultAvatar from '../../avatar';

function PanelListItem({
  applet: {
    logo, image, name, address, description, actions,
  },
  ...otherProps
}) {
  const avatar = (logo && logo.contentURL) || (image && image.contentURL) || <DefaultAvatar />;

  return (
    <BaseExpPanel
      avatar={avatar}
      primary={name}
      caption={address}
      secondary={description}
      actions={actions}
      {...otherProps}
    />
  );
}

PanelListItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    address: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};

PanelListItem.defaultProps = {
  applet: {
    address: '',
  },
};

export default withStyles(defaultStyles)(PanelListItem);
