import { IApplet } from 'packages/model/interface';
import useApplet from '../use-applet';

type ItemFetcherProps = {
  id: string;
  // eslint-disable-next-line react/require-default-props
  onItemFetched?: (applet: IApplet) => void;
};

function ItemFetcher({ id, onItemFetched }: ItemFetcherProps) {
  const applet = useApplet(id);
  if (onItemFetched) onItemFetched(applet);
  return null;
}

export default ItemFetcher;
