import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/card';
import CardBase from '../../../../view-widgets/card/base';

const getHostname = (url) => {
  try {
    return new URL(url).hostname;
  } catch (error) {
    return url;
  }
};

function CardView({ applet, classes, ...otherProps }) {
  const {
    name, url, description, image, actions, logo, customProperties,
  } = applet;
  const subheader = (
    <div className={classes.subheader}>
      {logo && logo.contentURL && (
        <object
          data={logo.contentURL}
          className={classes.logo}
          datatype="image/png"
          aria-label="logo"
        />
      )}
      <Typography variant="caption" noWrap>
        {getHostname(url)}
      </Typography>
    </div>
  );
  return (
    <CardBase
      image={image}
      title={name}
      description={description}
      subheader={subheader}
      actions={actions}
      classes={classes}
      customProperties={customProperties}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CardView);
