export const generateReferenceDate = (startDateUnix, interval) => {
  const secondsInWeek = 7 * 24 * 60 * 60;
  return startDateUnix + (interval * secondsInWeek);
};

const weekDaysUnixTimes = (referenceDateUnix) => {
  const secondsInDay = 24 * 60 * 60;
  const todayZeroIndex = new Date(referenceDateUnix * 1000).getUTCDay();
  const sundayTimeUnix = referenceDateUnix - (todayZeroIndex * secondsInDay);
  const unixTimeArray = [];
  for (let i = 0; i < 7; i += 1) {
    unixTimeArray.push(sundayTimeUnix + (i * secondsInDay));
  }
  return unixTimeArray;
};

export const byDaysTimestamps = (referenceDateUnix, byDays) => {
  if (!byDays.length) return [referenceDateUnix];
  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const weekDaysUnix = weekDaysUnixTimes(referenceDateUnix);
  const byDaysUnix = [];
  weekDays.forEach((day, index) => {
    if (byDays.indexOf(day) !== -1) {
      byDaysUnix.push(weekDaysUnix[index]);
    }
  });
  return byDaysUnix;
};

export const checkAgainstNow = (byDaysUnix) => {
  const nowUnix = Math.round(Date.now() / 1000);
  return byDaysUnix.filter(unixTime => unixTime >= nowUnix);
};

const nextWeeklyEvent = (applet, acc = 0) => {
  let newAcc = acc;

  const {
    startDate,
    recurrence: {
      recurrenceRule: {
        interval, byDays,
      },
    },
  } = applet;

  const byDaysUnix = byDaysTimestamps(startDate, byDays);
  let byDaysUnixGreater = checkAgainstNow(byDaysUnix);
  if (byDaysUnixGreater.length) {
    [newAcc, byDaysUnixGreater] = byDaysUnixGreater;
    return newAcc;
  }
  const newItem = {
    ...applet,
    startDate: generateReferenceDate(byDaysUnix[0], interval),
  };
  return nextWeeklyEvent(newItem, byDaysUnix[0]);
};

export default nextWeeklyEvent;
