import * as properties from './properties';
import * as thingProperties from '../Thing/properties';
import Card from './views/card';
import ListCardItem from './views/list-card-item';
import ListItem from './views/list-item';
import Accordion from './views/expansion-panel';
import Avatar from './avatar';
import BasicForm from './forms/basic';

const PlaceExtension = {
  views: {
    card: () => Card,
    listCardItem: () => ListCardItem,
    listItem: () => ListItem,
    expansionPanel: () => Accordion,
  },
  forms: {
    basic: () => BasicForm,
  },
  avatar: () => Avatar,
  properties: { ...thingProperties, ...properties },
  meta: {
    name: 'Anything',
  },
};
export default PlaceExtension;
