import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PriceView from '../../../../view-widgets/price';
import styles from '../../../styles/views/card';
import CardBase from '../../../../view-widgets/card/base';

function CardView({
  applet: {
    name, description, priceSpecification, image, actions, logo, customProperties,
  },
  classes,
  ...otherProps
}) {
  const priceSpec = priceSpecification
    && priceSpecification.price
    && (
    <Typography
      color="textSecondary"
      variant="h6"
      component="div"
    >
      <PriceView value={priceSpecification} />
    </Typography>
    );

  return (
    <CardBase
      image={image}
      logo={logo}
      title={name}
      hero={priceSpec}
      description={description}
      leftAction={priceSpec}
      actions={actions}
      classes={classes}
      customProperties={customProperties}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    priceSpecification: PropTypes.shape({
      price: PropTypes.number,
      priceCurrency: PropTypes.string,
    }),
    description: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CardView);
