import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import CardBase from 'packages/view-widgets/card/base';
import styles from '../../../styles/views/card';
import PhoneNumberView from '../phone-number-view';
import EmailView from '../email-view';

function CardView({
  applet: {
    name, description, image, actions, telephone, email, customProperties,
  },
  classes,
  ...otherProps
}) {
  const descriptionComp = description
    && (
      <Typography>
          {description}
      </Typography>
    );
  const icons = (
    <div>
      <PhoneNumberView telephone={telephone} size="medium" />
      <EmailView email={email} size="medium" />
    </div>
  );

  const secondary = (
    <div>
      {descriptionComp}
      {icons}
    </div>
  );
  return (
    <CardBase
      image={image}
      title={name}
      description={secondary}
      actions={actions}
      classes={classes}
      customProperties={customProperties}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CardView);
