import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import nextEvent from '../../utils/next-event';
import Base from '../../../../view-widgets/image-view/base';

function ImageViewer({
  applet,
  ...otherProps
}) {
  return (
    <Base
      imageURL={applet.image && applet.image.contentURL}
      primary={applet.name}
      secondary={moment.unix(nextEvent(applet)).calendar(null, { sameElse: 'dddd, MMMM Do YYYY, h:mm a' })}
      {...otherProps}
    />
  );
}

ImageViewer.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default ImageViewer;
