import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'packages/use-navigation/navigation-context';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import ActionButton from '../../../view-widgets/action-button';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linkInner: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
  },

  link: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 16,
    color: '#808080',
    textDecoration: 'none',
  },
};

function ActionsView({
  applet, classes, edit, editURL,
}) {
  const {
    actions,
  } = applet;
  // current limitation is 4 - makes no sense to litter the views with a billion actions
  const normalActions = actions && actions.slice(0, 4);
  return (
    <div className={classes.root}>
      {
        (normalActions && normalActions.length > 0)
        && normalActions.map((action, index) => {
          const key = `${action.name}-${index}-${action.target}-${editURL}`;
          return (
            <ActionButton action={action} key={key} />
          );
        })
      }
      {
        edit
        && (
        <Link to={`${editURL}`} href={`${editURL}`} className={classes.link}>
          {
            (normalActions && normalActions.length > 0)
              ? (
                <IconButton size="large">
                  <EditIcon />
                </IconButton>
              )
              : (
                <span className={classes.linkInner}>
                  <AddIcon />

                  Add actions
                </span>
              )
          }
        </Link>
        )
      }
    </div>
  );
}
ActionsView.propTypes = {
  applet: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  classes: PropTypes.shape({ }).isRequired,
  edit: PropTypes.bool,
  editURL: PropTypes.string,
};
ActionsView.defaultProps = {
  edit: false,
  editURL: '',
};
export default withStyles(styles)(ActionsView);
