import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'packages/use-navigation/navigation-context';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ActionButton from '../../action-button';
import defaultStyles from '../../../extensions/styles/views/expansion-panel';

const getAvatar = (avatar, primary) => {
  const srcString = (avatar && typeof avatar === 'string') ? avatar : null;
  const char = (typeof primary === 'string' && primary[0].toUpperCase()) || '';

  return (!avatar || srcString) ? (
    <Avatar
      src={srcString}
    >
      {char}
    </Avatar>
  ) : avatar;
};
function Base({
  avatar,
  primary,
  caption,
  secondary,
  actions,
  url,
  viewProps,
  classes,
}) {
  let TitleComp = 'div';
  let target = '';
  let rel = '';
  if (url && url.startsWith('/')) {
    TitleComp = Link;
  } else if (url) {
    TitleComp = 'a';
    if (!url.startsWith('tel:')) {
      target = '_blank';
      rel = 'noopener';
    }
  }
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      className={classes.root}
      elevation={viewProps.elevation}
      square={viewProps.square}
      onChange={(e, isExpanded) => { setExpanded(isExpanded); }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summaryRoot}>
        {getAvatar(avatar, primary)}
        <div className={classes.summary}>
          <Typography
            component={TitleComp}
            href={url}
            rel={rel}
            to={url}
            target={target}
            noWrap={!expanded}
            variant="subtitle1"
            className={classes.summaryText}
          >
            {primary}
          </Typography>
          <Typography variant="caption" noWrap={!expanded} className={classes.caption}>
            {caption && caption}
          </Typography>
        </div>
      </AccordionSummary>
      {
        secondary
        && (
          typeof secondary === 'string'
            ? (
              <AccordionDetails>
                <Typography>
                  {secondary}
                </Typography>
              </AccordionDetails>
            )
            : (
              <AccordionDetails>
                {secondary}
              </AccordionDetails>
            )
        )
    }
      {
        actions.length ? (
          <div className={classes.actions}>
            {actions.map(action => <ActionButton action={action} key={`${action.target}-BaseExpPanel`} />)}
          </div>
        ) : null
      }
    </Accordion>
  );
}

Base.propTypes = {
  avatar: PropTypes.node,
  primary: PropTypes.node.isRequired,
  caption: PropTypes.node,
  secondary: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  url: PropTypes.string,
  viewProps: PropTypes.shape({
    elevation: PropTypes.number,
    square: PropTypes.bool,
  }),
  classes: PropTypes.shape({}).isRequired,
};

Base.defaultProps = {
  avatar: undefined,
  caption: undefined,
  secondary: undefined,
  actions: [],
  viewProps: {},
  url: undefined,
};

export default withStyles(defaultStyles)(Base);
