import React from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import defaultStyles from '../../../styles/views/expansion-panel';
import PhoneNumberView from '../phone-number-view';
import EmailView from '../email-view';
import BaseExpPanel from '../../../../view-widgets/expansion-panel/base';
import DefaultAvatar from '../../avatar';

function PanelListItem({
  applet: {
    name, description, logo, image, actions, email, telephone,
  },
  classes,
  ...otherProps
}) {
  const detail = (
    <div>
      <AccordionDetails>
        <Typography className={classes.details}>
          {description}
        </Typography>
      </AccordionDetails>
      <PhoneNumberView telephone={telephone} />
      <EmailView email={email} />
    </div>
  );
  const avatar = (logo && logo.contentURL) || (image && image.contentURL) || <DefaultAvatar />;

  return (
    <BaseExpPanel
      avatar={avatar}
      primary={name}
      secondary={detail}
      actions={actions}
      classes={classes}
      {...otherProps}
    />
  );
}

PanelListItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
  classes: PropTypes.shape({}).isRequired,
};

PanelListItem.defaultProps = {
  applet: {
    description: '',
  },
};

export default withStyles(defaultStyles)(PanelListItem);
