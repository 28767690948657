import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

function EmailField({ onChange, ...props }) {
  const { maxLength } = props;
  const style = {
    div: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      margin: '0 auto',
    },
    textField: {
      color: '#00BCD4',
    },
  };
  return (
    <div style={style.div}>
      <TextField
        disabled={props.disabled}
        variant="standard"
        style={style.textField}
        placeholder="someone@somewhere.com"
        onChange={e => onChange(e.target.value)}
        {...props}
        inputProps={{ maxLength }}
        type="email"
        fullWidth
      />
    </div>
  );
}

EmailField.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
};

EmailField.defaultProps = {
  maxLength: undefined,
};

export default EmailField;
