import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TYPES from 'packages/db/redux/constants';
import { useAuthContext } from 'auth/context';

const useEffectOnce = func => useEffect(func, []);

const useAuth = () => {
  const localAuth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const authStateChangeCallback = (user) => {
    const userData = user ? user.toJSON() : {};
    if (!user || (localAuth && localAuth.uid !== userData.uid)) {
      dispatch({ type: TYPES.SET_USER_AUTH, auth: userData });
    }
  };
  const auth = useAuthContext();

  useEffectOnce(() => {
    let cleanup;
    (() => {
      cleanup = auth.onAuthStateChanged(authStateChangeCallback);
    })();
    return () => { if (cleanup) cleanup(); };
  });

  return localAuth;
};

export default useAuth;
