import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import listItemLoader from '../../extensions/loader/views/list-item';
import ListItemBaseView from './base';

export function ListItemView({ applet, ...otherProps }) {
  const {
    name, description, image, logo,
  } = applet;
  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;

  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && name ? name[0].toUpperCase() : null}
    </Avatar>
  );

  return (
    <ListItemBaseView
      primary={name}
      secondary={description}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};

ListItemView.defaultProps = {
  applet: {
    name: undefined,
    description: undefined,
  },
};

function ListItemDefault(props) {
  const { applet } = props;
  const DefaultComponent = useCallback(() => <ListItemView {...props} />, [props]);
  const Component = listItemLoader(applet.type, DefaultComponent);
  return <Component {...props} />;
}

ListItemDefault.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    type: PropTypes.string,
  }),
};

ListItemDefault.defaultProps = {
  applet: {
    name: undefined,
    description: undefined,
  },
};

export default ListItemDefault;
