const parseHttp = (url: URL) => {
  const website = url.host;
  const detail = { title: website, description: `Visit ${website}`, linkText: 'Go' };
  if ((website === 'fb.me' || website === 'facebook.com') && url.pathname.split('/')[1]) {
    const username = url.pathname.split('/')[1];
    detail.title = `${username} on Facebook`;
    detail.description = `Follow ${username} on Facebook`;
    detail.linkText = 'Open Facebook';
  }
  if (website === 'linkedin.com' && url.pathname.split('/')[2]) {
    const username = url.pathname.split('/')[2];
    detail.title = `${username} on LinkedIn`;
    detail.description = `Follow ${username} on LinkedIn`;
    detail.linkText = 'Open LinkedIn';
  }
  return detail;
};
const parseUSSD = (url: URL) => {
  const detail = { title: 'Dial Short Code', description: '', linkText: 'Dial' };
  const ussd = url.href.replace('tel:', '');
  const summary = ussd.replace(/<(.*;)?(label:)([^;]*)([^>])*>/g, '<$3>');
  detail.description = `Dial ${summary}`;
  return detail;
};

const parseTel = (url: URL) => {
  if (url.href.startsWith('tel:*') && url.href.endsWith('#')) {
    return parseUSSD(url);
  }
  const detail = { title: '', description: '', linkText: '' };
  const phoneRegex = /[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*(\s|$)?/;
  const match = url.href.match(phoneRegex);
  if (match) {
    const phoneNumber = match[0];
    detail.title = phoneNumber;
    detail.description = `Dial ${phoneNumber}`;
    detail.linkText = 'Dial';
  }
  return detail;
};

const parseSms = (url: URL) => {
  const detail = { title: 'SMS', description: 'Send An SMS', linkText: 'Compose' };
  const phoneRegex = /[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*(\s|$)?/;
  const match = url.href.match(phoneRegex);
  const body = url.searchParams.get('body');
  if (match) {
    const phoneNumber = match[0];
    detail.title = 'Text';
    detail.description = `Send a text to ${phoneNumber}`;
    detail.linkText = 'Text';
    if (body) {
      detail.description = `Text "${body}" to ${phoneNumber}`;
    }
  }
  return detail;
};

const parseEmail = (url: URL) => {
  const detail = { title: 'Email', description: 'Send An Email', linkText: 'Compose' };
  const email = url.pathname;
  detail.description = `Send an e-mail to ${email}`;
  const subject = url.searchParams.get('subject');
  if (subject) {
    detail.description = `${detail.description} with subject "${subject}"`;
  }
  return detail;
};

const genericParser = (link: string) => ({
  description: `Go to ${link}`,
  linkText: 'Go',
  title: link,
});
const getParser = (protocol: string) => {
  if (protocol === 'http:') return parseHttp;
  if (protocol === 'https:') return parseHttp;
  if (protocol === 'sms:') return parseSms;
  if (protocol === 'mailto:') return parseEmail;
  if (protocol === 'tel:') return parseTel;

  return null;
};
const describeLink = (link: string) => {
  try {
    const url = new URL(link);
    const parser = getParser(url.protocol);
    if (parser) return parser(url);
  } catch {
    // ignores errors
  }
  return genericParser(link);
};

export default describeLink;
