import getAppletPath from '../../../utils/create-url';

export default {
  name: 'Contact Points',
  edit: {
    form: 'applet-input',
    label: 'Edit Contact Points',
    handler: value => value,
    getAltEditLink: (parent) => {
      const searchParams = new URLSearchParams();
      searchParams.set('property', 'contactPoints');
      searchParams.set('allowedTypes', ['ContactPoint']);
      const pathname = `/${getAppletPath(parent)}/~create/Thing`;
      return {
        label: 'Create New',
        pathname,
        search: `?${searchParams.toString()}`,
        state: {
          presets: {
            name: 'Contacts',
            description: `Contact points for ${parent.name}`,
          },
        },
      };
    },
  },
};
