import React from 'react';
import PropTypes from 'prop-types';
import PhoneIcon from '@mui/icons-material/Phone';
import { Avatar } from '@mui/material';
import ListItemBaseView from '../../../../view-widgets/list-item/base';
import getHostname from '../utils/get-hostname';

function getAvatarFallBack(applet) {
  if (applet.url && applet.url.startsWith('tel:')) {
    return <PhoneIcon />;
  }
  return applet.name && applet.name[0].toUpperCase();
}

function ListItemView({ applet, ...otherProps }) {
  const { name, logo, image } = applet;

  const avatarSrc = (logo && logo.contentURL) || (image && image.contentURL) || null;
  const avatar = (
    <Avatar src={avatarSrc}>
      {!avatarSrc && getAvatarFallBack(applet)}
    </Avatar>
  );
  return (
    <ListItemBaseView
      primary={name}
      secondary={getHostname(applet.url)}
      avatar={avatar}
      {...otherProps}
    />
  );
}

ListItemView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
};

ListItemView.defaultProps = {
  applet: {
    description: '',
  },
};

export default ListItemView;
