import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import weekDaysEasy from '../../utils/weekdays-easy';
import toLocalTime from '../../utils/to-local-time';
import to12HourInterpreter from '../../utils/to12HourInterpreter';

function OpeningHoursView({ openingHours: { days, openHours, timezoneOffset }, classes }) {
  const allDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  const openingHours = openHours && openHours.map((hoursObject) => {
    if (hoursObject.from === hoursObject.to) return '24 Hours';
    return Object.values(hoursObject).map(hourz => to12HourInterpreter(toLocalTime(hourz, timezoneOffset))).join(' - ');
  });
  let openingHoursStr = 'Closed';
  if (openingHours) {
    const lastEntry = openingHours.pop();
    openingHoursStr = openingHours.length === 0 ? lastEntry : `${openingHours.join(', ')} and ${lastEntry}`;
  }
  return (
    <div className={classes.container}>
      <span>
        {weekDaysEasy(days.map(day => allDays.indexOf(day)), { plural: true })}
      </span>
      <span>
        {openingHoursStr}
      </span>
    </div>
  );
}

OpeningHoursView.propTypes = {
  openingHours: PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.string),
    openHours: PropTypes.arrayOf(PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    })),
    pop: PropTypes.func,
    length: PropTypes.number,
    join: PropTypes.func,
    timezoneOffset: PropTypes.number,
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '50% auto',
    gridColumnGap: '30px',
    justifyContent: 'start',
  },
};
export default withStyles(styles)(OpeningHoursView);
