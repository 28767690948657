import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import PriceView from '../../../../view-widgets/price';
import defaultStyles from '../../../styles/views/mini-card';
import MiniCardBase from '../../../../view-widgets/mini-card/base';

function MiniCard({
  applet: {
    name, description, priceSpecification, image,
  },
  classes,
  ...otherProps
}) {
  const price = priceSpecification
                && priceSpecification.price
                && <PriceView value={priceSpecification} />;
  return (
    <MiniCardBase
      imageURL={image && image.contentURL}
      title={name}
      description={description}
      subheader={price}
      classes={classes}
      {...otherProps}
    />
  );
}

MiniCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    priceSpecification: PropTypes.shape({
      price: PropTypes.number,
      priceCurrency: PropTypes.string,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(defaultStyles)(MiniCard);
