import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/card';
import CardBase from '../../../../view-widgets/card/base';

function CardView({
  applet: {
    name, brand, description, logo, image, actions, customProperties,
  },
  classes,
  ...otherProps
}) {
  return (
    <CardBase
      image={image}
      logo={logo}
      title={name}
      subheader={brand}
      description={description}
      actions={actions}
      classes={classes}
      customProperties={customProperties}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CardView);
