import React from 'react';
import NotificationsContext from '..';

const withNotify = Component => (function (props) {
  return (
    <NotificationsContext.Consumer>
      {notify => <Component notify={notify} {...props} />}
    </NotificationsContext.Consumer>
  );
});

export default withNotify;
