import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import QVview from '..';

const useClasses = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    overflowX: 'auto',
    alignItems: 'baseline',
    justifyItems: 'start',
    justifyContent: 'start',
    marginTop: '16px',
  },
});
function QVList({ values }) {
  const classes = useClasses();
  return (
    <div className={classes.root}>
      { values.map((value, i) => {
        const key = `$${i}`;
        return (
          <QVview key={key} value={value} />
        );
      })}
    </div>
  );
}
QVList.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.node,
    unit: PropTypes.node,
    label: PropTypes.node,
  })).isRequired,
};
export default QVList;
