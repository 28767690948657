import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import { Card, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Image from 'components/image';
import styles from '../../../extensions/styles/views/list-card-item';

function ListCardBase({
  imageURL, title, detail, classes, url, viewProps,
}) {
  let CardComp = 'div';
  let target = '';
  let rel = '';
  if (url && url.startsWith('/')) {
    CardComp = Link;
  } else if (url) {
    CardComp = 'a';
    if (!url.startsWith('tel:')) {
      target = '_blank';
      rel = 'noopener';
    }
  }

  return (
    <Card
      component={CardComp}
      to={url}
      href={url}
      rel={rel}
      target={target}
      className={classes.root}
      elevation={viewProps.elevation}
      square={viewProps.square}
    >
      <div className={classes.cardContent}>
        {detail && (
        <Typography variant="caption" className={classes.caption}>
          {detail}
        </Typography>
        )}
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
      </div>
      {imageURL && <Image src={imageURL} className={classes.image} alt="" />}
    </Card>
  );
}
ListCardBase.propTypes = {
  imageURL: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.node.isRequired,
  detail: PropTypes.node,
  url: PropTypes.string,
  viewProps: PropTypes.shape({
    square: PropTypes.bool,
    elevation: PropTypes.number,
  }),
};
ListCardBase.defaultProps = {
  imageURL: undefined,
  url: undefined,
  detail: null,
  viewProps: {},
};
export default withStyles(styles)(ListCardBase);
