import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Card, CardContent, CardActions, CardHeader, Typography, Button,
} from '@mui/material';
import inDialog from '../../../../components/in-dialog';
import withContainer from './container';

function parsePrompt(prompt) {
  const parsedPrompt = {};// JSON.parse(prompt.replace(/[</>]/g, ''));
  prompt.replace(/[<>]/g, '').split(';').forEach((pair) => {
    const [key, value] = pair.split(':');
    if (key && value) {
      parsedPrompt[key] = value;
    }
  });
  if (!parsedPrompt.label) { return null; }
  return parsedPrompt;
}

function parsePrompts(prompts) {
  return prompts.map(prompt => parsePrompt(prompt)).filter(entry => entry !== null);
}

function UserPrompt({
  actionName,
  prompts,
  promptReplacements,
  buttonProps,
  onPromptChange,
  onClose,
}) {
  let { href } = buttonProps;
  Object.keys(promptReplacements).forEach((prompt) => {
    href = href.replace(prompt, promptReplacements[prompt]);
  });
  const newButtonProps = { ...buttonProps, href };

  let buttonDisabled = false;
  Object.values(promptReplacements).forEach((replacement) => {
    if (!replacement) buttonDisabled = true;
  });

  const parsedPrompts = parsePrompts(prompts);

  const actionIsValid = prompts.length === parsedPrompts.length;

  const unSupportedTypes = ['image', 'button', 'checkbox', 'radio', 'reset', 'search', 'submit', 'hidden'];
  const pickerTypes = ['date', 'datetime-local', 'week', 'range', 'month', 'time', 'file', 'color'];

  return (
    <Card>
      <CardHeader title={actionName} />
      <CardContent>
        {
          actionIsValid
            ? parsedPrompts.map(({ label, type }, index) => {
              const lowerCaseType = type ? type.toLowerCase() : undefined;
              const reactKey = `${label}-${index}`;
              return (
                <TextField
                  variant="standard"
                  fullWidth
                  key={reactKey}
                  label={label}
                  type={
                    lowerCaseType && !unSupportedTypes.includes(lowerCaseType)
                      ? lowerCaseType
                      : undefined
                  }
                  value={promptReplacements[prompts[index]]}
                  InputLabelProps={
                    lowerCaseType && pickerTypes.includes(lowerCaseType)
                      ? {
                        shrink: true,
                      }
                      : undefined
                  }
                  onChange={(e) => {
                    onPromptChange(Object.keys(promptReplacements)[index], e.target.value);
                  }}
                />
              );
            })
            : (
              <Typography color="secondary">
                Sorry, something is not right
              </Typography>
            )
        }
      </CardContent>
      <CardActions>
        {
          actionIsValid
          && (
            <Button
              disabled={buttonDisabled}
              {...newButtonProps}
            >
              Submit
            </Button>
          )
        }
        <Button
          color={!actionIsValid ? 'secondary' : undefined}
          variant={!actionIsValid ? 'contained' : undefined}
          onClick={() => {
            onClose();
          }}
        >
          {
            actionIsValid
              ? 'Cancel'
              : 'Close'
          }
        </Button>
      </CardActions>
    </Card>
  );
}
UserPrompt.propTypes = {
  actionName: PropTypes.string.isRequired,
  prompts: PropTypes.arrayOf(PropTypes.string).isRequired,
  promptReplacements: PropTypes.shape({}).isRequired,
  buttonProps: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
  onPromptChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

const DialogActionUserPrompt = inDialog(withContainer(UserPrompt));
export default DialogActionUserPrompt;
