import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import NameInput from '../../../../forms/name-input';
import DescriptionInput from '../../../../forms/description-input';

const styles = {
  inputWrapper: {
  },
  imageInput: {
    width: 128,
    height: 128,
    backgroundColor: 'lightgrey',
    margin: '0 auto',
  },
  nameDescriptionDiv: {

  },
};

function Form(props) {
  const { value, onChange, isValidCallback } = props;
  const [name, setName] = useState(value.name);
  const [description, setDescription] = useState(value.description);
  useEffect(() => {
    if (isValidCallback) {
      if (name && name.trim()) {
        isValidCallback(true);
      } else {
        isValidCallback(false);
      }
    }
  }, [isValidCallback, name]);
  const update = useCallback(() => {
    onChange({
      ...value, name, description: description || '', type: 'Thing',
    });
  }, [description, name, onChange, value]);
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  return (
    <div style={styles.nameDescriptionDiv}>
      <NameInput
        key="thing-name-input"
        value={name}
        onChange={onNameChange}
        onBlur={update}
        required
      />
      <div>
        <DescriptionInput
          key="thing-description-input"
          rows={2}
          onBlur={update}
          value={description}
          onChange={onDescriptionChange}
        />
      </div>
    </div>
  );
}

Form.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isValidCallback: PropTypes.func,
};

Form.defaultProps = {
  value: { name: '', description: '' },
  isValidCallback: undefined,
};

export default Form;
