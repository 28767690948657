import React from 'react';
import PropTypes from 'prop-types';
import NameInput from '../../../../forms/name-input';
import DateTimeInput from '../../../../forms/date-time-picker';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  datePickerDiv: {
    marginTop: '2em',
    marginBottom: '1em',
    width: '100%',
  },
};

function BasicForm(props) {
  const { value, onChange, isValidCallback } = props;
  const { name, startDate } = {
    ...value,
    name: (value && value.name) || '',
    startDate: (value && value.startDate) || Math.round(new Date().getTime() / 1000),
  };
  if (isValidCallback) {
    if (value && value.name && value.name.trim()) {
      isValidCallback(true);
    } else {
      isValidCallback(false);
    }
  }
  function update(params) {
    onChange({ ...value, ...{ name, startDate }, ...params });
  }
  return (
    <div style={styles.container}>
      <NameInput
        maxLength={80}
        label="Event Name"
        value={name}
        onChange={(e) => { update({ name: e.target.value }); }}
      />
      <div style={styles.datePickerDiv}>
        <DateTimeInput
          value={(startDate)}
          onChange={(val) => { update({ startDate: val }); }}
        />
      </div>
    </div>
  );
}

BasicForm.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.number,
  }),
  isValidCallback: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

BasicForm.defaultProps = {
  value: undefined,
  isValidCallback: undefined,
};

export default BasicForm;
