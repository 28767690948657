export default (applet) => {
  if (applet && applet._meta) {
    if (applet.type === 'User') {
      return applet._meta.username ? `@${applet._meta.username}` : '';
    }
    if (applet._meta.appId === applet._meta.appletId) {
      return `@${applet._meta.ownerName}/${applet._meta.appId}`;
    }
    return `@${applet._meta.ownerName}/${applet._meta.appId}/${applet._meta.appletId}`;
  }
  return '';
};
