/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import Image from 'components/image';

type ImageWidgetProps = {
  src: string;
  [key: string]: unknown;
};

function ImageWidget(props:ImageWidgetProps, ref:any) {
  const [loaded, setLoaded] = useState(false);
  const [hidden, setHidden] = useState(false);
  if (hidden) return null;

  return (
    <Image
      onError={() => setHidden(true)}
      onLoad={() => { setLoaded(true); setHidden(false); }}
      className={loaded ? 'loaded' : 'loading'}
      {...props}
    />
  );
}
export default React.forwardRef(ImageWidget);
