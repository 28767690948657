import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from '../../../styles/views/card';
import CardBase from '../../../../view-widgets/card/base';

function CardView({
  applet: {
    name,
    address,
    description,
    image,
    actions,
    customProperties,
  },
  classes,
  ...otherProps
}) {
  const addressComp = address && (
    <Typography variant="body1">
      {address}
    </Typography>
  );

  const descriptionComp = description
    && (
      <Typography variant="body2">
          {description}
      </Typography>
    );

  const secondary = (
    <div>
      {addressComp}
      {descriptionComp}
    </div>
  );

  return (
    <CardBase
      image={image}
      title={name}
      description={secondary}
      actions={actions}
      classes={classes}
      customProperties={customProperties}
      {...otherProps}
    />
  );
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    address: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CardView);
