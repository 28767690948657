import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import PriceView from '../../../../view-widgets/price';
import defaultStyles from '../../../styles/views/expansion-panel';
import BaseExpPanel from '../../../../view-widgets/expansion-panel/base';
import DefaultAvatar from '../../avatar';

function PanelListItem({
  applet: {
    logo, image, name, description, priceSpecification, actions,
  },
  ...otherProps
}) {
  const avatar = (logo && logo.contentURL) || (image && image.contentURL) || <DefaultAvatar />;

  return (
    <BaseExpPanel
      avatar={avatar}
      primary={name}
      caption={
      priceSpecification
      && priceSpecification.price
      && <PriceView value={priceSpecification} />
    }
      secondary={description}
      actions={actions}
      {...otherProps}
    />
  );
}

PanelListItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
    priceSpecification: PropTypes.shape({
      price: PropTypes.number,
      priceCurrency: PropTypes.string,
    }),
  }).isRequired,
};

export default withStyles(defaultStyles)(PanelListItem);
