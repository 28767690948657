import * as thingProperties from '../Thing/properties';
import thing from '../Thing';
import * as properties from './properties';
import Card from './views/card';
import MiniCard from './views/mini-card';
import ListItem from './views/list-item';
import Avatar from './avatar';
import BasicForm from './forms/basic';

const { appletId, ...otherThingProperties } = thingProperties;
const UserExtension = {
  views: {
    ...thing.views,
    card: () => Card,
    miniCard: () => MiniCard,
    listItem: () => ListItem,
  },
  forms: {
    basic: () => BasicForm,
  },
  avatar: () => Avatar,
  properties: { ...otherThingProperties, ...properties },
  meta: {
    name: 'User',
  },
};
export default UserExtension;
