import { createStyles } from '@mui/styles';

const styles = createStyles(theme => ({
  root: {
    display: 'flex',
    '& >*:first-child:not($image)': {
      padding: '16px',
    },
    justifyContent: 'space-between',
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
  },

  image: {
    maxWidth: '100px',
    maxHeight: '100px',
    margin: '8px',
    borderRadius: '4px',
    flexShrink: '0',
    objectFit: 'cover',
  },

  caption: {
    color: theme.palette.text.hint,
    marginBottom: '4px',
  },
  title: {
  },

  cardContent: {
    padding: '8px !important',
    overflowX: 'auto',
  },
}));

export default styles;
