import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import nextEvent from '../../utils/next-event';
import BaseExpPanel from '../../../../view-widgets/expansion-panel/base';
import DefaultAvatar from '../../avatar';

function PanelListItem({
  applet,
  ...otherProps
}) {
  const {
    logo, image, name, description, actions,
  } = applet;

  const avatar = (logo && logo.contentURL) || (image && image.contentURL) || <DefaultAvatar />;

  return (
    <BaseExpPanel
      avatar={avatar}
      primary={name}
      caption={moment.unix(nextEvent(applet)).calendar(null, { sameElse: 'dddd, MMMM Do YYYY, h:mm a' })}
      secondary={description}
      actions={actions}
      {...otherProps}
    />
  );
}

PanelListItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.number.isRequired,
    description: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};

PanelListItem.defaultProps = {
  applet: {
    description: '',
  },
};

export default PanelListItem;
