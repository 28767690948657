import React from 'react';
import PropTypes from 'prop-types';
import TelephoneInput from '../../../../forms/telephone-field';
import EmailInput from '../../../../forms/email-field';
import NameInput from '../../../../forms/name-input';

function Form({
  value,
  onChange,
  isValidCallback,
}) {
  const { name, telephone, email } = {
    name: '', telephone: '', email: '', ...value,
  };
  if (isValidCallback) {
    if (value && value.name && value.name.trim()) {
      isValidCallback(true);
    } else {
      isValidCallback(false);
    }
  }
  function update(params) {
    onChange({
      ...value, name, telephone, email, ...params,
    });
  }
  return (
    <div>
      <NameInput
        value={name}
        placeholder="Customer service"
        onChange={({ target: { value: val } }) => {
          update({ name: val, contactType: val });
        }}
      />
      <TelephoneInput
        label="Phone Number"
        value={telephone}
        onChange={({ target: { value: val } }) => {
          update({
            telephone: val,
            actions: [{ target: `tel:${val}`, name: 'Call', form: 'Dial Action' }],
          });
        }}
      />
      <EmailInput
        label="Email Address"
        value={email}
        onChange={(val) => {
          onChange({ ...value, email: val });
        }}
      />
    </div>
  );
}

Form.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isValidCallback: PropTypes.func,
};
Form.defaultProps = {
  value: undefined,
  isValidCallback: undefined,
};

export default Form;
